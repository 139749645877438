import { modalDamagesToggle, modalRkiToggle } from './AlertService';
import { Validator } from './ValidationService';

export const isInsuranceInfoFilled = (uiComp) => {
	if (!uiComp.showValidNowOption) {
		return false;
	}
	return isValidFromFilled(uiComp.model);
};

export const isValidFromFilled = (model) => {
	if (model.additionalInfo.validNow) {
		if (model.additionalInfo.validNow === 'nej' && !Validator.isValidFromDate(model.additionalInfo.validFromDate)) {
			return false;
		}
		return true;
	}
	return false;
};

export const handleAdditionalStepClick = (value, name: string, model, cms) => {
	switch (name) {
		case 'skader':
			model.additionalInfo.skader = value;
			if (cms.damagesPopupTitle && !cms.damagesOk.includes(value)) {
				modalDamagesToggle(cms);
			}
			break;
		case 'rki':
			model.additionalInfo.rki = value;
			if (value === 'ja' && cms.rkiPopupTitle) {
				modalRkiToggle(cms);
			}
			break;
		case 'validNow':
			model.additionalInfo.validNow = value;
			if (model.additionalInfo.validNow === 'nej') {
				setTimeout(() => {
					const input = document.getElementById('validFromDate');
					if (input) {
						input.focus();
					}
				}, cms.initialTimeout);
			}
			break;
	}
};
