import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import store from '@/store/store';

export const isOpenHours = (open: string, closed: Array<string>) => {
	// const open: string = '07.30, 15.30, 1.2.3.4.5';
	//const open: string = comp.cms.open;
	if (!open) {
		return false;
	}
	const timeArr = open.split(',');

	const days = timeArr[2].split('.').map((item) => {
		return parseInt(item);
	});
	const now = new Date();

	let isOpen = false;
	if (days.includes(now.getDay())) {
		const start = timeArr[0].split('.').map((item) => {
			return parseInt(item);
		});
		const end = timeArr[1].split('.').map((item) => {
			return parseInt(item);
		});

		const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), start[0], start[1]);
		const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), end[0], end[1]);

		isOpen = now.getTime() > startTime.getTime() && now.getTime() < endTime.getTime();
	}

	if (isOpen) {
		// check for closing days
		isOpen = !isClosingDay(closed);
	}
	return isOpen;
};

export const setOpenTxtOnReceipt = (multipleProducts: boolean, comp) => {
	const isOpen = isOpenHours(comp.cms.open, comp.cms.closingDays);
	let txt: string;
	if (isOpen) {
		txt = comp.cms.receiptOpenTxt;
	} else {
		txt = comp.cms.receiptClosedTxt;
	}

	if (multipleProducts) {
		if (store.state.missingCalculationsInBasket) {
			if (isOpen) {
				// overwrite txt
				txt = comp.cms.receiptOpenMissingProduct;
			} else {
				// overwrite txt
				txt = comp.cms.receiptClosedMissingProduct;
			}
		} else {
			txt += comp.cms.receiptBasketTxt;
		}
	} else {
		txt += comp.cms.receiptOneTxt;
	}
	comp.cardReceipt.text = txt;
};

/***** private methods *****************/
const isClosingDay = (closed: Array<string>): boolean => {
	try {
		const closingDays: Array<string> = closed;
		if (!closingDays) {
			return false;
		}
		let isClosingDay = false;
		const now = new Date();
		const date = now.getMonth() + 1 + '-' + now.getDate();
		isClosingDay = closingDays.includes(date);
		return isClosingDay;
	} catch (e) {
		exception(e);
		return false;
	}
};
