import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Transition as _Transition, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  class: "card__body",
  key: "cardBody"
}
const _hoisted_4 = { class: "card__text" }
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["href", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpSaleComponent = _resolveComponent("UpSaleComponent")!
  const _component_usabillaInPageComponent = _resolveComponent("usabillaInPageComponent")!

  return (_ctx.componentInit)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createVNode(_Transition, { name: "slide-vertical" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              id: _ctx.card.name,
              class: _normalizeClass([
					_ctx.card.cardHeight,
					_ctx.card.inactivate ? 'disabled ' : '',
					_ctx.card.textCenter ? 'text-center' : '',
					' card card--base',
				]),
              key: "card"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h2", {
                      class: "mb-4",
                      textContent: _toDisplayString(_ctx.card.title)
                    }, null, 8, _hoisted_5),
                    _createElementVNode("div", {
                      innerHTML: _ctx.card.text
                    }, null, 8, _hoisted_6)
                  ])
                ]),
                _createVNode(_component_UpSaleComponent, { ref: "upsale" }, null, 512),
                _createElementVNode("div", {
                  class: "card__text",
                  innerHTML: _ctx.cms.receiptTxtEnd
                }, null, 8, _hoisted_7),
                (_ctx.usabillaBlock)
                  ? (_openBlock(), _createBlock(_component_usabillaInPageComponent, {
                      key: 0,
                      model: _ctx.usabillaBlock
                    }, null, 8, ["model"]))
                  : _createCommentVNode("", true),
                (!_ctx.hasUpsale)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      class: _normalizeClass([_ctx.card.buttonStyle, 'm-0 mt-4']),
                      href: _ctx.link,
                      textContent: _toDisplayString(_ctx.linkTxt)
                    }, null, 10, _hoisted_8))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_2)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.componentShow]
        ])
      ]))
    : _createCommentVNode("", true)
}