import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page__title"
}
const _hoisted_2 = {
  key: 0,
  class: "h1-pretitle"
}
const _hoisted_3 = { class: "h1-underline h1-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_epi_edit = _resolveDirective("epi-edit")!

  return (_ctx.showTitle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.preTitle)
          ? _withDirectives((_openBlock(), _createElementBlock("p", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.preTitle), 1)
            ])), [
              [_directive_epi_edit, 'PreTitle']
            ])
          : _createCommentVNode("", true),
        _withDirectives((_openBlock(), _createElementBlock("h1", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.mainTitle), 1)
        ])), [
          [_directive_epi_edit, 'MainTitle']
        ])
      ]))
    : _createCommentVNode("", true)
}