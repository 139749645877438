import BuyAccidentChildren from './BuyAccidentChildrenComponent';
import { AccidentChildrenSettings, productId } from './AccidentChildrenSettings';
import { cloneDeep } from 'lodash';
import store from '@/store/store';
import AxiosService from '@/views/calculators/services/axiosService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	finishCalculation,
	getDiscount,
	mapCoveragesFromProductConfig,
	mockAccident,
	prepareCalculations,
	setAccidentPrices,
	setAllPlusCustomerPrices,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { modalHttp250Toggle } from '../services/AlertService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import { AccidentChildrenModel } from './AccidentChildrenModel';
import { PackageConfig } from '../types/CommonModel';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { Calculator } from '../BuyInsuranceComponent';

export default class AccidentCalculator implements Calculator {
	public model: AccidentChildrenModel; // the data structure for state and properties
	public cms: AccidentChildrenSettings; // settings from EPI
	public calcConfig: any;
	public axiosService: AxiosService;
	private readonly buyAccident: BuyAccidentChildren; // component using calculator
	public calculatorInfo: Array<any>;

	constructor(buyAccident: BuyAccidentChildren) {
		this.buyAccident = buyAccident;
		this.model = buyAccident.model;
		this.cms = buyAccident.cms;
		this.calculatorInfo = JSON.parse(buyAccident.calculatorInfoBlock.markup);

		const ulykkeCalculatorUrl =
			defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/accident/offers';
		this.axiosService = new AxiosService(ulykkeCalculatorUrl);

		this.setUpConfig();
	}

	public setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model);
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		prepareCalculations(this.model, this.cms, false);
		// reset calculation
		this.setUpConfig();
		// this.model.calculation.abCalc.calculated = true;
		try {
			const rebate = getDiscount(this.cms);
			this.buyAccident.model.calculation.discount = rebate.discount;
			this.buyAccident.model.calculation.discountDisplay = rebate.discountDisplay;

			const promises = [];
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				calc.summedBasePrice = 0;
				calc.summedStatutoryFee = 0; // not returned by api
				calc.summedTotalPrice = 0;

				calc.summedYearlyBasePrice = 0;
				calc.summedYearlyStatutoryFee = 0; // not returned by api
				calc.summedYearlyPriceTotal = 0;
			});
			for (let i = 0; i <= this.model.familyInfo.personCountId; i++) {
				const age = this.model.familyInfo['age' + i];
				const workId = this.model.familyInfo['workId' + i];

				this.model.calculation.abCalc.packages.forEach((pack) => {
					const features = this.getFeatures(pack);
					promises.push(this.calculateQuickQuote(pack.name, age, features, workId));
				});
			}
			await Promise.all(promises);
			this.sumUpPrices();
			this.model.calculation.abCalc.calculated = true;

			finishCalculation(this.model, true);

			updateCalcKeys(this.model);
			return Promise.resolve(true);
		} catch (err) {
			exception(err, 'Accident offer');
			modalHttp250Toggle(this.cms, 400, 'child accident calculator', this);
			store.state.showSpinner = false;
			store.state.calculatorContext.calculating = false;
			return Promise.resolve(false);
		}
	}

	private sumUpPrices() {
		this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
			calc.basePrice = calc.summedBasePrice;
			calc.statutoryFee = calc.summedStatutoryFee;
			calc.totalPrice = calc.summedTotalPrice;

			calc.yearlyBasePrice = calc.summedYearlyBasePrice;
			calc.yearlyStatutoryFee = calc.summedYearlyStatutoryFee;
			calc.yearlyPriceTotal = calc.summedYearlyPriceTotal;

			setAllPlusCustomerPrices(this.model, calc, true);

			setAllPlusCustomerPrices(this.model, calc, false);
		});
	}

	private async calculateQuickQuote(
		productType: string,
		childAge: string,
		features: Array<any>,
		workId: string
	): Promise<any> {
		let customerAge = parseInt(childAge);
		if (customerAge < this.cms.minChildAge) {
			customerAge = this.cms.minChildAge;
		}
		if (!features) {
			return Promise.reject();
		}
		const createOffer: any = {
			product: {
				product_name: productId,
				features,
				parameters: {
					age: customerAge,
					postalCode: this.model.personInfo.zipCode,
				},
			},
		};

		workId ? (createOffer.product.parameters.employment = workId) : undefined;

		try {
			const res = await this.axiosService.postRetry(undefined, createOffer);
			const offer = res.data as { price: { monthly: number; annually: number } };
			if (!offer?.price?.annually) {
				throw new Error();
			}
			this.model.calculation.abCalc.packs.get(this.model.calculation.abCalc.excessIdDefault).forEach((calc) => {
				if (calc.name === productType) {
					setAccidentPrices(calc, offer, this.model, true);
				}
			});

			return Promise.resolve();
		} catch (err) {
			exception(err);
			modalHttp250Toggle(this.cms, 400, 'child accident calculator', this);
			if (isDevelop && this.cms.mockData) {
				mockAccident(this.model, productType, true);
			} else {
				return Promise.reject(err);
			}
		}
	}

	private getFeatures(pack: PackageConfig) {
		const features = [];
		pack.includedCoveragesExpandedIds.forEach((riskId) => {
			const cov = cloneDeep(
				this.model.calculation.abCalc.coveragesDetails.filter((coverage) => riskId + '' === coverage.riskId)[0]
			);
			cov.name = cov.riskId;
			features.push(cov);
		});
		return features;
	}
}
