import { stripProductIndex } from '@/views/calculators/services/ProductService';
import { CalculatorData } from './modules/calculatorContext';

export default {
	getEpiRouting: (state) => {
		return state.epiRouting;
	},
	getRecaptchaSiteKey: (state) => {
		return state.recaptchaSiteKey;
	},

	getUsabillaState: (state) => {
		return state.usabilla;
	},

	getThemeContext: (state) => {
		const themeContext = state.epiContent?.model?.theme;
		return themeContext ? themeContext : 'almbrand';
	},

	/** Calculators */

	getActiveCalculator: (state): CalculatorData => {
		return state.calculatorContext[state.calculatorContext.active];
	},
	getCalculator:
		(state) =>
		(name: string): CalculatorData => {
			return state.calculatorContext[name];
		},
	getActiveCalculatorInx: (state): number => {
		return state.calculatorContext.activeInx;
	},
	getCalculatorCommonModel: (state) => {
		return state.calculatorContext.commonModel;
	},
	getBasket: (state) => {
		return state.calculatorContext.basket;
	},
	getConsentSetInApp: (state) => {
		return state.consentSetInApp;
	},
	getSelectableGroup: (state) => (groupId: string) => {
		return state.selectableContext.selectGroups.get(groupId)
			? state.selectableContext.selectGroups.get(groupId)
			: [];
	},
	getSelectableGroupUnique: (state) => (groupId: string) => {
		const group = state.selectableContext.selectGroups.get(groupId)
			? state.selectableContext.selectGroups.get(groupId)
			: [];
		const uniqueGroup = [];
		group.forEach((name) => {
			const cleanedName = name.replace(/\d/g, '');
			if (!uniqueGroup.includes(cleanedName)) {
				uniqueGroup.push(cleanedName);
			}
		});
		return uniqueGroup;
	},
	getCustomerCalculation: (state) => {
		return state.calculatorContext.customer;
	},
};
