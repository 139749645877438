import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'RadioComponent',
	props: {
		name: String,
		id: String,
		labelledby: { type: String, default: undefined },
		inputValue: { type: String, default: undefined },
		preSelect: { type: String, default: undefined },
		disabled: { type: Boolean, default: false },
		size: { type: String, default: 'default' },
		type: { type: String, default: 'column' },
		testProp: { type: String, default: undefined },
		trackingEnabled: { type: Boolean, default: true },
		trackingLabel: { type: String, default: undefined },
		change: Boolean,
	},
})
export default class RadioComponent extends Vue {
	name!: string;
	id!: string;
	labelledby?: string;
	inputValue?: any;
	preSelect?: any;
	disabled?: boolean;
	size?: string; // 'default' | 'small'
	type?: string; // 'column' | 'inline'
	testProp?: string; // Used for targetting component data with automatic test
	trackingEnabled!: boolean; // tracks to gtm, by default sends event action: radio, label: content of slot
	trackingLabel?: string; // NB! required if radio has no label/slot content
	// @Provide() change: boolean = false;
	change: boolean;

	// LIFECYCLE
	public mounted() {
		if (this.preSelect !== undefined) {
			if (this.preSelect === this.inputValue) {
				this.changeItem();
			}
		}
	}

	// METHODS
	public changeItem() {
		const item = (this as any).$refs[this.name + this.id];
		(item as HTMLInputElement).checked = true;
		this.changed();
	}

	public changed() {
		(this as any).$emit('RadioComponent', {
			name: this.name,
			id: this.id,
			value: this.inputValue !== undefined ? this.inputValue : this.change,
		});
	}

	// COMPUTED
	get inlineType() {
		return this.type === 'inline';
	}

	get trackingAttributes() {
		const label: string = (this as any).$slots?.default[0]?.text || this.inputValue || this.id || this.name;

		return this.trackingEnabled
			? {
					'data-gtm-action': 'radio',
					'data-gtm-label': this.trackingLabel !== undefined ? `${this.trackingLabel} - ${label}` : label,
			  }
			: null;
	}
}
