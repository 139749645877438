import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick", "onKeyup", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default"),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chunks, (chunk, inx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: _ctx.id + 'segC' + inx,
        class: _normalizeClass(['segmented-control', _ctx.theme])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chunk, (index) => {
          return (_openBlock(), _createElementBlock("button", {
            type: "button",
            key: _ctx.id + 'ctrl' + index,
            class: _normalizeClass(['segmented-control__item', { 'segmented-control__item--selected': index === _ctx.selectedControl }]),
            style: _normalizeStyle('width: ' + 100 / _ctx.controlsPerRow + '%'),
            onClick: ($event: any) => (_ctx.selected(index)),
            onKeyup: _withKeys(($event: any) => (_ctx.selected(index)), ["enter","space"]),
            textContent: _toDisplayString(_ctx.labels[index])
          }, null, 46, _hoisted_1))
        }), 128))
      ], 2))
    }), 128))
  ]))
}