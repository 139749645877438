import { ActionContext, Dispatch } from 'vuex';
import pick from 'lodash/pick';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export const CALCULATOR_LOADED = 'CALCULATOR_LOADED';
export const INIT_CALCULATOR = 'INIT_CALCULATOR';
export const CALCULATOR_UPDATE_COMMON_MODEL = 'CALCULATOR_UPDATE_COMMON_MODEL';
export const UPDATE_ACTIVE_CALCULATOR = 'UPDATE_ACTIVE_CALCULATOR';
export const UPDATE_CALCULATOR_INDEX = 'UPDATE_CALCULATOR_INDEX';
export const REMOVE_CALCULATOR = 'REMOVE_CALCULATOR';
export const BASKET_LOADED = 'BASKET_LOADED';
export const UPDATE_BASKET = 'UPDATE_BASKET';
export const RESET_CALCULATORS = 'RESET_CALCULATORS';
export const DISCOUNT_UPDATE = 'DISCOUNT_UPDATE';

const MUTATE_BASKET = 'MUTATE_BASKET';
const MUTATE_CALCULATOR = 'MUTATE_CALCULATOR';
const MUTATE_CALCULATOR_UPDATE_COMMON_MODEL = 'MUTATE_CALCULATOR_UPDATE_COMMON_MODEL';
const MUTATE_CALCULATOR_ACTIVE = 'MUTATE_CALCULATOR_ACTIVE';
const MUTATE_CALCULATOR_INDEX = 'MUTATE_CALCULATOR_INDEX';
const MUTATE_REMOVE_CALCULATOR = 'MUTATE_REMOVE_CALCULATOR';
const MUTATE_RESET_CALCULATORS = 'MUTATE_RESET_CALCULATORS';

const personInfoCopyProperties = [
	'customerAge',
	'address',
	'zipCode',
	'zipName',
	'streetName',
	'houseNr',
	'floorNr',
	'doorNr',
	'kvhxValue',
	'familyMemberCount',
	'familyMemberCountId',
	'almbrandCustomer',
	'existingAlmBrandProducts',
];
const additionalInfoCopyProperties = ['validNow', 'validFromDate'];
const contact_informationCopyProperties = ['name', 'phone', 'email', 'customerNo', 'cpr'];

const createTransactionId = (): string => {
	try {
		return Math.random().toString(16).slice(2, 10);
	} catch (e) {
		exception(e);
		return new Date().getTime() + '';
	}
};
export interface CalculatorData {
	cms: any;
	model: any;
}
export interface CustomerCalculation {
	useLogout: boolean;
	hasCustomerData: boolean;
	customerFetched: boolean;
	agreementsFetched: boolean;
	customer: {
		contact_information: any;
		personInfo: any;
	};
}
export interface CalculatorCommonModel {
	personInfo: {
		customerAge?;
		address?;
		zipCode?;
		zipName?;
		streetName?;
		houseNr?;
		floorNr?;
		doorNr?;
		kvhxValue?;
		familyMemberCount?;
		familyMemberCountId?;
		almbrandCustomer?;
		existingAlmBrandProducts: Array<string>;
	};
	additionalInfo: {
		validNow?;
		validFromDate?;
	};
	contact_information: {
		name?;
		phone?;
		email?;
		customerNo?;
		cpr?;
	};
	showExistingAlmBrandProducts: boolean;
}
export enum BasketProductsEnum {
	BIL_FORSIKRING0 = 'bilforsikring0',
	BIL_FORSIKRING1 = 'bilforsikring1',
	BIL_FORSIKRING2 = 'bilforsikring2',
	BIL_FORSIKRING3 = 'bilforsikring3',
	BIL_FORSIKRING4 = 'bilforsikring4',
	BIL_FORSIKRING5 = 'bilforsikring5',
	ULYKKES_FORSIKRING0 = 'ulykkesforsikring0',
	ULYKKES_FORSIKRING1 = 'ulykkesforsikring1',
	ULYKKES_FORSIKRING2 = 'ulykkesforsikring2',
	ULYKKES_FORSIKRING3 = 'ulykkesforsikring3',
	ULYKKES_FORSIKRING4 = 'ulykkesforsikring4',
	ULYKKES_FORSIKRING5 = 'ulykkesforsikring5',
	ULYKKES_FORSIKRING6 = 'ulykkesforsikring6',
	ULYKKES_FORSIKRING7 = 'ulykkesforsikring7',
	ULYKKES_FORSIKRING8 = 'ulykkesforsikring8',
	ULYKKES_FORSIKRING9 = 'ulykkesforsikring9',
	BOERNEULYKKES_FORSIKRING0 = 'børneulykkesforsikring0',
	INDBO_FORSIKRING0 = 'indboforsikring0',
	HUS_FORSIKRING0 = 'husforsikring0',
	FRITIDSHUS_FORSIKRING0 = 'fritidshusforsikring0',
	REJSE_FORSIKRING0 = 'rejseforsikring0',
	HUNDE_FORSIKRING0 = 'hundeforsikring0',
	BOERNE_FAMILIE_FORSIKRING0 = 'børnefamilieforsikring0',
}
export interface CalculatorContextState {
	[BasketProductsEnum.BIL_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.BIL_FORSIKRING1]: CalculatorData;
	[BasketProductsEnum.BIL_FORSIKRING2]: CalculatorData;
	[BasketProductsEnum.BIL_FORSIKRING3]: CalculatorData;
	[BasketProductsEnum.BIL_FORSIKRING4]: CalculatorData;
	[BasketProductsEnum.BIL_FORSIKRING5]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING1]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING2]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING3]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING4]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING5]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING6]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING7]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING8]: CalculatorData;
	[BasketProductsEnum.ULYKKES_FORSIKRING9]: CalculatorData;
	[BasketProductsEnum.BOERNEULYKKES_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.INDBO_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.HUS_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.FRITIDSHUS_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.REJSE_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.HUNDE_FORSIKRING0]: CalculatorData;
	[BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0]: CalculatorData;
	commonModel: CalculatorCommonModel;
	active: string;
	activeInx: number;
	basket: CalculatorData;
	customer: CustomerCalculation;
	calculating: boolean;
	transactionId: string;
}

const state: CalculatorContextState = {
	[BasketProductsEnum.BIL_FORSIKRING0]: undefined,
	[BasketProductsEnum.BIL_FORSIKRING1]: undefined,
	[BasketProductsEnum.BIL_FORSIKRING2]: undefined,
	[BasketProductsEnum.BIL_FORSIKRING3]: undefined,
	[BasketProductsEnum.BIL_FORSIKRING4]: undefined,
	[BasketProductsEnum.BIL_FORSIKRING5]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING0]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING1]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING2]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING3]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING4]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING5]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING6]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING7]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING8]: undefined,
	[BasketProductsEnum.ULYKKES_FORSIKRING9]: undefined,
	[BasketProductsEnum.BOERNEULYKKES_FORSIKRING0]: undefined,
	[BasketProductsEnum.INDBO_FORSIKRING0]: undefined,
	[BasketProductsEnum.HUS_FORSIKRING0]: undefined,
	[BasketProductsEnum.FRITIDSHUS_FORSIKRING0]: undefined,
	[BasketProductsEnum.REJSE_FORSIKRING0]: undefined,
	[BasketProductsEnum.HUNDE_FORSIKRING0]: undefined,
	[BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0]: undefined,
	commonModel: {
		personInfo: { existingAlmBrandProducts: [] },
		additionalInfo: {},
		contact_information: {},
		showExistingAlmBrandProducts: true,
	},
	active: undefined,
	activeInx: 0,
	basket: undefined,
	customer: {
		useLogout: false,
		hasCustomerData: false,
		customerFetched: false,
		agreementsFetched: false,
		customer: {
			contact_information: {},
			personInfo: {},
		},
	},
	calculating: false,
	transactionId: createTransactionId(),
};

const resetProduct = (productName, state) => {
	if (productName !== state.active) {
		state[productName] = undefined;
	}
};

const mutations = {
	[MUTATE_CALCULATOR](state: any, calculatorData: CalculatorData) {
		state[state.active] = calculatorData;
	},
	[MUTATE_CALCULATOR_UPDATE_COMMON_MODEL](state: any, model: any) {
		if (state[state.active]?.model?.multipleProducts || model.forceCopy) {
			if (model.fromCommonModel) {
				if (!state.commonModel.personInfo.almbrandCustomer) {
					// don't copy if common model is not filled
					return;
				}
				// DEBUG console.log(`copy from commonModel to ${state.active}`);
				const personInfo = pick(state.commonModel.personInfo, personInfoCopyProperties);
				const addInfo = pick(state.commonModel.additionalInfo, additionalInfoCopyProperties);
				const contactInfo = pick(state.commonModel.contact_information, contact_informationCopyProperties);
				Object.assign(state[state.active].model.personInfo, personInfo);
				Object.assign(state[state.active].model.additionalInfo, addInfo);
				Object.assign(state[state.active].model.contact_information, contactInfo);
				state[state.active].model.showExistingAlmBrandProducts = state.commonModel.showExistingAlmBrandProducts;
			} else {
				// DEBUG console.log(`copy from ${state.active} to commonModel`);
				const personInfo = pick(state[state.active].model.personInfo, personInfoCopyProperties);
				const addInfo = pick(state[state.active].model.additionalInfo, additionalInfoCopyProperties);
				const contactInfo = pick(
					state[state.active].model.contact_information,
					contact_informationCopyProperties
				);

				Object.assign(state.commonModel.personInfo, personInfo);
				Object.assign(state.commonModel.additionalInfo, addInfo);
				Object.assign(state.commonModel.contact_information, contactInfo);
				state.commonModel.showExistingAlmBrandProducts = state[state.active].model.showExistingAlmBrandProducts;
				if (state.commonModel.showExistingAlmBrandProducts === true) {
					//triggered only first time existing product have been filled
					state.commonModel.showExistingAlmBrandProducts = false;
				}
			}
		}
	},
	[MUTATE_CALCULATOR_ACTIVE](state: any, active: string) {
		let a = active;
		const productInx = active.replace(/[^\d]/g, '');
		if (productInx === '') {
			a += state.activeInx;
		}
		state.active = a;
		// console.log('setting active calculator', state.active);
	},
	[MUTATE_REMOVE_CALCULATOR](state: any, calcName: string) {
		state[calcName] = undefined;
	},
	[CALCULATOR_LOADED](state: any) {
		//subscription event
	},
	[DISCOUNT_UPDATE](state: any) {
		//subscription event
	},
	[MUTATE_BASKET](state: any, basket: CalculatorData) {
		state.basket = basket;
	},
	[BASKET_LOADED](state: any) {
		//subscription event
	},
	[MUTATE_RESET_CALCULATORS](state: any) {
		resetProduct(BasketProductsEnum.BIL_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.BIL_FORSIKRING1, state);
		resetProduct(BasketProductsEnum.BIL_FORSIKRING2, state);
		resetProduct(BasketProductsEnum.BIL_FORSIKRING3, state);
		resetProduct(BasketProductsEnum.BIL_FORSIKRING4, state);

		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING1, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING2, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING3, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING4, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING5, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING6, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING7, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING8, state);
		resetProduct(BasketProductsEnum.ULYKKES_FORSIKRING9, state);

		resetProduct(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.INDBO_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.HUS_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.FRITIDSHUS_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.REJSE_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.HUNDE_FORSIKRING0, state);
		resetProduct(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0, state);

		state.commonModel = {
			personInfo: { existingAlmBrandProducts: [] },
			additionalInfo: {},
			contact_information: {},
			showExistingAlmBrandProducts: true,
		};
		// state.active = undefined;
		// state.activeInx = 0;
		//state.basket = undefined;
		state.customer = {
			useLogout: false,
			hasCustomerData: false,
			customerFetched: false,
			agreementsFetched: false,
			customer: {
				contact_information: {},
				personInfo: {},
			},
		};
	},
	[MUTATE_CALCULATOR_INDEX](state: any, activeInx: number) {
		state.activeInx = activeInx;
	},
};

const actions = {
	async [INIT_CALCULATOR]({ commit, state, dispatch }: ActionContext<any, any>, calculatorData: CalculatorData) {
		commit(MUTATE_CALCULATOR, calculatorData);
		dispatch(CALCULATOR_LOADED); // notify subscribers that calculator is loaded
	},
	async [UPDATE_ACTIVE_CALCULATOR]({ commit, state, dispatch }: ActionContext<any, any>, active: string) {
		commit(MUTATE_CALCULATOR_ACTIVE, active);
	},
	async [CALCULATOR_LOADED]({ commit, state, dispatch }: ActionContext<any, any>) {
		//subscription event
		commit(CALCULATOR_LOADED);
	},
	async [DISCOUNT_UPDATE]({ commit, state, dispatch }: ActionContext<any, any>) {
		//subscription event
		commit(DISCOUNT_UPDATE);
	},

	async [CALCULATOR_UPDATE_COMMON_MODEL]({ commit, state, dispatch }: ActionContext<any, any>, model: any) {
		commit(MUTATE_CALCULATOR_UPDATE_COMMON_MODEL, model);
	},
	async [REMOVE_CALCULATOR]({ commit, state, dispatch }: ActionContext<any, any>, calcName: string) {
		commit(MUTATE_REMOVE_CALCULATOR, calcName);
	},
	async [UPDATE_BASKET]({ commit, state, dispatch }: ActionContext<any, any>, basket: CalculatorData) {
		// if (!state.basket) {
		commit(MUTATE_BASKET, basket);
		// }
		dispatch(BASKET_LOADED); // notify subscribers that calculator is loaded
	},
	async [BASKET_LOADED]({ commit, state, dispatch }: ActionContext<any, any>) {
		//subscription event
		commit(BASKET_LOADED);
	},
	async [RESET_CALCULATORS]({ commit, state, dispatch }: ActionContext<any, any>) {
		commit(MUTATE_RESET_CALCULATORS);
		//also subscription event
	},
	async [UPDATE_CALCULATOR_INDEX]({ commit, state, dispatch }: ActionContext<any, any>, activeInx: number) {
		commit(MUTATE_CALCULATOR_INDEX, activeInx);
		//also subscription event
	},
};

export default {
	state,
	mutations,
	actions,
};
