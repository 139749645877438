
	import { defineComponent } from 'vue';
	import { defaultConfig } from '@/cms/api/ApiConfig';
	import store from '@/store/store';
	import { runAtStart } from '@/startupRunners';

	export default defineComponent({
		data() {
			return {
				firstLoad: true,
			};
		},
		computed: {
			canonicalUrl() {
				return store.state.epiContent.model?.canonicalUrl;
			},
			themeName: () => {
				return store?.getters?.getThemeContext;
			},
		},
		methods: {
			isTouchDevice() {
				const isTouchDevice =
					'ontouchstart' in window ||
					navigator.maxTouchPoints > 0 ||
					// eslint-disable-next-line
					(window.navigator as any).msMaxTouchPoints > 0;
				store.commit('setDeviceType', isTouchDevice);
				return isTouchDevice;
			},
			setCanonicalUrl() {
				const tag = document.querySelector('link[rel="canonical"]');
				if (tag) tag.setAttribute('href', this.canonicalUrl.url);
			},
		},
		mounted() {
			const element = document.getElementById('app');

			// fallback to production
			const domainUrl = element?.getAttribute('data-domain') || 'https://beregn.almbrand.dk';

			// fallback to production
			const contentDeliveryApiUri = element?.getAttribute('data-contentdelivery-url') || '/api/ab/v3/content';
			// const language = element?.getAttribute("data-language") ?? "";
			// store.state.epiContent.modelLanguage = language;

			// Epi settings for content delivery
			defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
			defaultConfig.baseUrl = domainUrl;
			defaultConfig.selectAllProperties = true;
			defaultConfig.expandAllProperties = true;

			this.$root['themeBrand'] = element?.getAttribute('data-theme-brand');
			this.$root['themeBrandSimple'] = element?.getAttribute('data-theme-brand-simple');
			this.$root['themeFavicon'] = element?.getAttribute('data-theme-favicon');
			this.$root['appVersion'] = element?.getAttribute('data-app-version');
			this.$root['appVersionMessage'] = element?.getAttribute('data-app-version-message');

			/*
			 * If the `epiEditMode` parameter is present we know we're
			 * in either edit- or preview mode and should include following scripts.
			 */
			if (this.$route.query.epieditmode || document.location.search.includes('epieditmode')) {
				const domainScript = document.createElement('script');
				domainScript.innerHTML = "document.domain = 'localhost';";
				document.body.appendChild(domainScript);
				const communicationScript = document.createElement('script');
				communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
				document.body.appendChild(communicationScript);
			}

			this.isTouchDevice();

			runAtStart();
		},
		beforeUnmount() {
			window.removeEventListener('resize', this.handleResize);
			window.removeEventListener('scroll', this.handleScroll);
		},
	});
