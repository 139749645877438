import Coverage from './Coverage';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'CoverageComponent',
	components: {},
	props: {
		coverage: Object,
		track: Boolean, // track using css classes
		trackGtm: Boolean, // track using gtm attributes
		trackGtmLabel: String, // optional label for gtm attributes
	},
})
export default class CoverageComponent extends Vue {
	coverage: Coverage;
	track: boolean; // track using css classes
	trackGtm: boolean; // track using gtm attributes
	trackGtmLabel?: string; // optional label for gtm attributes

	public refreshKey: string = Math.random() + '';

	public animateController() {
		if (this.coverage?.description) {
			this.coverage.selected = !this.coverage.selected;
			this.refreshKey = Math.random() + '';
		}
	}
	public getTrackGtmLabel(): string {
		if (this.trackGtm) {
			if (this.trackGtmLabel) {
				return `${this.trackGtmLabel} - ${this.coverage.title}`;
			}
			return this.coverage.title;
		}
		return undefined;
	}
}
