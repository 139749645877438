import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_autocomplete = _resolveComponent("autocomplete")!

  return (!_ctx.readonlyValue)
    ? (_openBlock(), _createBlock(_component_autocomplete, {
        key: 0,
        autocomplete: _ctx.autocompleteNative,
        onInput: _ctx.search,
        onOnSelect: _ctx.handleSubmit,
        onBlur: _ctx.blur,
        onFocus: _ctx.focus,
        id: _ctx.autocompleteId,
        results: _ctx.results,
        ref: _ctx.acRef,
        debounce: _ctx.debounce,
        placeholder: _ctx.placeholder,
        max: _ctx.max,
        "input-class": ['input__field'],
        "display-item": _ctx.displayItem
      }, null, 8, ["autocomplete", "onInput", "onOnSelect", "onBlur", "onFocus", "id", "results", "debounce", "placeholder", "max", "display-item"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "flex input__field disabled readonly input--valid",
          innerHTML: _ctx.readonlyValue
        }, null, 8, _hoisted_2)
      ]))
}