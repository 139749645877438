import { SelectableData, UPDATE_SELECTABLE, SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { DISCOUNT_UPDATE, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import {
	basketChangeDiscountOnCalculation,
	getDiscount,
	getDiscountForAmountProducts,
} from '../services/CalculationService';
import { formatNumber } from '../services/ValidationService';
import { path } from '../services/NavigationService';
import OverviewBasketMiniStepComponent from '../basket/steps/OverviewBasketMiniStepComponent.vue';
import PopoverComponent from '@/sharedcomponents/baseComponents/popoverComponent/popoverComponent';
import { trackViewCard } from '@/views/calculators/services/trackingUtility';

@Options({
	name: 'BasketWidgetComponent',
	components: {
		OverviewBasketMiniStepComponent,
	},
	props: {
		productName: { type: String, default: undefined }, // productname for tracking and Basket
		showBasket: { type: Boolean, default: true },
		forceOpen: { type: Boolean, default: false },
	},
})
export default class BasketWidgetComponent extends Vue {
	productName?: string; // productname for tracking and Basket
	showBasket?: boolean;
	forceOpen?: boolean;

	public basket: Array<string> = [];
	public productsInBasket = 0;
	private unsubscribe: Function;
	public discount = '';

	public beforeUnmount() {
		this.unsubscribe();
	}

	public async mounted() {
		// make sure current product is in basket
		const uniqueBasket = store.getters.getSelectableGroupUnique('basket');
		if (this.productName && !uniqueBasket.includes(this.productName)) {
			const toggle: SelectableData = {
				group: 'basket',
				id: this.productName + '0',
				isSelected: true,
				sortMultiple: true,
			};
			await store.dispatch(UPDATE_SELECTABLE, toggle);
		}

		this.setBasket();

		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				this.setBasket();
				this.setDiscountUi();
			} else if (action.type === RESET_CALCULATORS) {
				this.discount = '';
			} else if (action.type === DISCOUNT_UPDATE) {
				this.setDiscountUi();
			}
		});
		this.setDiscountUi();
	}

	public get showContent(): boolean {
		return this.forceOpen || this.productName !== undefined;
	}

	public setDiscountUi() {
		this.discount = '';
		const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		if (cms) {
			const discount = getDiscount(cms);
			if (discount.discountDisplay) {
				this.discount = cms.miniBasketDiscountPreTxt + discount.discountDisplay + cms.miniBasketDiscountPostTxt;
			}
		}
		this.handleDiscountChange();
	}

	private setBasket(): void {
		this.basket = store.getters.getSelectableGroup('basket');
		this.productsInBasket = this.basket.length;
	}

	public track() {
		setTimeout(() => {
			// wait for popup to open/close
			if ((this.$refs.popover as PopoverComponent).isOpen) {
				const idName = 'popup_basket';
				const cardType = 'cart popover';
				trackViewCard(idName, idName, cardType, true);
				//.then( () => trackViewItemList(idName, idName, true, true));
			}
		}, 250);
	}

	public get forsikringerLink() {
		//const url = urlService.ensureCorrectLink(path + 'forsikringer');
		// return url;
		return path + 'forsikringer';
	}

	private handleDiscountChange() {
		const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		const rebate = getDiscount(cms);
		const basket = store.getters.getSelectableGroup('basket');

		if (store.getters.getBasket?.model) {
			const model = store.getters.getBasket.model;
			if (rebate.discount !== model.calculation.discount) {
				model.calculation.discount = rebate.discount;
				model.calculation.discountDisplay = rebate.discountDisplay;
			}
		}

		basket.forEach((product) => {
			const calc = store.getters.getCalculator(product);
			if (calc?.model) {
				let update = false;
				const campaign = calc.model.campaign;
				if (campaign.valid && campaign.productStepDiscount) {
					// possible change of campaign discount
					const calculatedDiscount = getDiscountForAmountProducts(campaign);
					if (campaign.discount !== calculatedDiscount) {
						campaign.discount = calculatedDiscount;
						campaign.originalDiscount = campaign.discount;
						campaign.discountDisplay = formatNumber.format(100 * campaign.discount) + ' %';
						update = true;
					}
				}

				// KLK SPLASH OK
				// always update highlights if splash campaign with product dependency
				if (!update) {
					update = campaign.splash && store.state.campaignSplashDependentActive;
				}

				if (calc.model.choosePackage?.selectedPackage) {
					if (update || calc.model.calculation.discount !== rebate.discount) {
						calc.model.overview.highlights = undefined;
						basketChangeDiscountOnCalculation(calc, rebate);
					}
				}
			}
		});
	}
}
