import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal__content__header" }
const _hoisted_2 = { class: "modal__content__body" }
const _hoisted_3 = {
  key: 1,
  class: "modal__content__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.useModalTrigger)
      ? (_openBlock(), _createElementBlock("button", _mergeProps({
          key: 0,
          class: "modal-trigger",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
          ref: "toggle"
        }, _ctx.trackingAttributesTrigger), [
          _renderSlot(_ctx.$slots, "trigger")
        ], 16))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isActive)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(['modal', { 'modal--active': _ctx.isActive, 'modal--dismissible': _ctx.isDismissible }]),
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.isDismissible ? _ctx.toggle() : ''), ["self"]))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(['modal__content', _ctx.modalTypeClass]),
                ref: "modalContent"
              }, [
                (_ctx.isDismissible)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "modal__close",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggle()), ["self"]))
                    }, " x "))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "header")
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "body")
                ]),
                (_ctx.hasFooterSlot)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "footer")
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}