import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import { handleSpecialCarDiscounts } from '../services/CalculationService';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildPersonInfo,
	buildPaymentInfo,
	handleOrder,
	buildGlobalSumsInfo,
} from '../services/OrderService';
import { formatNumber } from '../services/ValidationService';
import MapOrderFlow from '../types/MapOrderFlow';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		const specificFields = {
			desc: '',
			tracking: '',
		};
		if (this.model.calculation?.addressSupplement > 1) {
			specificFields.desc += `<li><h2><i>Adresse tillæg (${formatNumber.format((this.model.calculation.addressSupplement - 1) * 100)}%)</i></h2></li>`;
			specificFields.tracking += '/addressSupplement';
		}

		if (!this.model.carInfo.licenseplate || this.model.carInfo.licenseplate === 'ikke oplyst') {
			specificFields.desc += `<li><h2><i>Nummerplade ikke oplyst</i></h2></li>`;
			specificFields.tracking += '/nolicenseplate';
		}

		await handleOrder(this.uiComp, 'Car submitOrder', specificFields);
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		if (this.pack?.specialDiscount) {
			const discount = handleSpecialCarDiscounts(this.model, this.cms);
			if (discount.specialDiscountType === 'vehicleGroup') {
				desc += addProp(
					`Bilgruppe rabat (${this.cms.vehicleDiscountGroups.join(', ')}) inkluderet i pris`,
					formatNumber.format(100 * this.pack.specialDiscount) + ' %'
				);
			} else if (discount.specialDiscountType === 'fuelType') {
				desc += addProp(
					`El/Hybrid rabat (bilgrp. ${this.cms.electricFuelTypeVehicleGroups.join(', ')}) inkluderet i pris`,
					formatNumber.format(100 * this.pack.specialDiscount) + ' %'
				);
			} else {
				desc += addProp(
					`UKENDT rabat inkluderet i pris`,
					formatNumber.format(100 * this.pack.specialDiscount) + ' %'
				);
			}
		}

		desc += buildPersonInfo(this.model);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += '<h2>Forsikring</h2>';
		desc += addProp('Rki', this.model.additionalInfo.rki);
		if (!(this.uiComp as any).isNewDriver) {
			desc += addProp('Eksisterende forsikring', this.model.additionalInfo.existInsurance);
			if (this.model.additionalInfo.existInsurance === 'nej') {
				desc += addProp('Tidligere forsikring', this.model.additionalInfo.existFormerInsurance);
				if (this.model.additionalInfo.existFormerInsurance === 'ja') {
					desc += addProp('Tidligere selskab', this.model.additionalInfo.formerInsurance);
				}
			} else {
				desc += addProp('Nuværende selskab', this.model.additionalInfo.existingInsurance);
			}
		} else {
			desc += addProp('Eksisterende forsikring', 'Ingen');
			desc += addProp('Nuværende selskab', 'Ingen, ny bilist');
		}

		desc += addProp('Gyldig hurtigst mulig', this.model.additionalInfo.validNow);
		if (this.model.additionalInfo.validNow === 'nej') {
			desc += addProp('Gyldig pr.', this.model.additionalInfo.validFromDate);
		}

		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);
		desc += addProp('Årlig kørselsbehov', this.model.carInfo.yearlyMileage);
		desc += addProp('Antal år med egen bil', this.model.carInfo.carOwnerYears);
		desc += addProp('Selvrisiko', (this.uiComp as any).getExcessAmount());

		desc += buildGlobalSumsInfo(this.model);

		desc += buildPaymentInfo(
			this.model,
			this.pack,
			this.model.choosePackage.useTiaOffer ? 'Tia beregning' : 'AU_PB beregning'
		);

		desc += buildCreditCardInfo(this.model, this.cms);

		desc += '<h2>Bil oplysninger</h2>';
		desc += addProp(
			'Nummerplade',
			this.model.carInfo.licenseplate ? this.model.carInfo.licenseplate.toUpperCase() : 'Ikke oplyst'
		);
		desc += addProp('Bil', this.model.carInfo.car);
		desc += addProp('Variant', this.model.carInfo.variantName);
		desc += addProp('Årgang', this.model.carInfo.year);
		desc += addProp('Kid', this.model.carInfo.kid + '');
		desc += addProp('VariantId', this.model.carInfo.variantId);
		desc += addProp('Vægt', this.model.carInfo.carWeight + '');
		desc += addProp('Drivmiddel', this.model.carInfo.fuelType);
		desc += addProp('VehicleGroup', this.model.carInfo.vehicleGroup);
		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
