import { mapState } from 'vuex';
import { AccidentChildrenSettings } from '../AccidentChildrenSettings';
import FamilyMemberComponent from '../../components/FamilyMemberComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';

@Options({
	name: 'FamilyInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		FamilyMemberComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class FamilyInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: AccidentChildrenSettings; // settings from CMS (store)

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public familyMemberCountSelected(result: any) {
		if (result && result.selectedValue) {
			this.model.familyInfo.personCount = result.selectedValue;
			this.model.familyInfo.personCountId = this.cms.personCountList.indexOf(this.model.familyInfo.personCount);
			nextTick(() => {
				// wait for transition to begin showing 1. name field
				setTimeout(() => {
					const input = document.getElementById('name0');
					if (input) {
						input.focus();
					}
				}, 300);
			});
			return;
		}
		this.model.familyInfo.personCount = undefined;
		this.model.familyInfo.personCountId = 0;
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
