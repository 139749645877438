import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "button-container text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonTapComponent = _resolveComponent("ButtonTapComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.card.image.url)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            class: "card__image",
            src: _ctx.card.image.url,
            alt: ""
          }, null, 8, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      innerHTML: _ctx.card.text
    }, null, 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ButtonTapComponent, {
        class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3']),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextStep())),
        textContent: _toDisplayString(_ctx.card.linkText),
        "data-gtm": _ctx.cms.dataGtm,
        "data-gtm-click-text": _ctx.card.routePath
      }, null, 8, ["class", "textContent", "data-gtm", "data-gtm-click-text"])
    ])
  ]))
}