import { TileVariantEnum } from '@/cms/definitions/content-types';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';
import TileButton from './_partials/tileButton/TileButton.vue';
import TileSelectable from './_partials/tileSelectable/TileSelectable.vue';

@Options({
	name: 'TileBlockComponent',
	components: {
		TileSelectable,
		TileButton,
	},
	computed: mapState<any>({
		isEditable: (state) => state.epiContext.isEditable,
		parentModel: (state) => state.epiContent.model,
	}),
	props: {
		model: Object as PropType<any>,
	},
})
export default class TileBlockComponent extends Vue {
	model: any;
	TileVariantEnum: any = TileVariantEnum;
	created() {
		if (this.model.tileVariant === 4) {
			this.model.tileVariant = TileVariantEnum.Select;
		} else if (this.model.tileVariant === 5) {
			this.model.tileVariant = TileVariantEnum.Button;
		}
	}
}
