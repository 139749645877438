enum InsurancesProductEnum {
	BIL_FORSIKRING = 'bilforsikring',
	BAAD_FORSIKRING = 'bådforsikring',
	KNALLERT_FORSIKRING = 'knallertforsikring',
	TRAKTOR_FORSIKRING = 'traktorforsikring',
	MOTORCYKEL_FORSIKRING = 'motorcykelforsikring',
	HUS_FORSIKRING = 'husforsikring',
	INDBO_FORSIKRING = 'indboforsikring',
	FRITIDSHUS_FORSIKRING = 'fritidshusforsikring',
	SAELGERANSVARS_FORSIKRING = 'sælgeransvarsforsikring',
	EJERSKIFTE_FORSIKRING = 'ejerskifteforsikring',
	HESTE_FORSIKRING = 'hesteforsikring',
	HUNDE_FORSIKRING = 'hundeforsikring',
	ULYKKES_FORSIKRING = 'ulykkesforsikring',
	GRAVIDITETS_FORSIKRING = 'graviditetsforsikring',
	LOEN_FORSIKRING = 'lønsikring',
	SYGDOMS_FORSIKRING = 'sygdomsforsikring',
	UNGDOMS_FORSIKRING = 'ungdomsforsikring',
	KORTTIDSREJSE_FORSIKRING = 'korttidsrejse',
	BOERNEULYKKES_FORSIKRING = 'børneulykkesforsikring',
	REJSE_FORSIKRING = 'rejseforsikring',
	BYGNINGS_FORSIKRING = 'bygningsforsikring',
	PAAHAENGSVOGNS_FORSIKRING = 'påhængsvognsforsikring',
	CAMPINGVOGNS_FORSIKRING = 'campingvognsforsikring',
	MOTORKOERETOEJS_FORSIKRING = 'motorkøretøjsforsikring',
	VETERAN_FORSIKRING = 'veteranforsikring',
	ERHVERVSANSVARS_FORSIKRING = 'erhvervsansvarsforsikring',
	ERHVERV_OG_PRODUKTANSVAR_FORSIKRING = 'erhvervs- og produktansvar',
	BOERNE_FAMILIE_FORSIKRING = 'børnefamilieforsikring',
	KOLLEKTIVULYKKE = 'kollektiv ulykke',
}

export default InsurancesProductEnum;
