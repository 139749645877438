import { Vue, Options } from 'vue-class-component';
@Options({
	name: 'ButtonComponent',
	props: {
		name: { type: String, default: 'btn' },
		id: String,
		btnType: String,
		btnClass: String,
		label: String,
		icon: String,
	},
})
export default class ButtonComponent extends Vue {
	name?: string;
	id: string;
	btnType: string;
	btnClass: string;
	label: string;
	icon: string;

	public clicked() {
		this.$emit('ButtonComponent', this);
	}
}
