import store from '@/store/store';
import { Calculator } from '../BuyInsuranceComponent';
import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { capitalizeFirstLetter } from './UiStepService';
import { stripProductIndex } from './ProductService';

export interface Modal {
	show: boolean;
	id: string;
	track?: boolean;
	trackToken?: string;
	errorType?: string;
	productName?: string;
	stepName?: string;
	trackMsg?: string;
	content?: string;
	btnType?: 'tel' | 'button' | 'mailto' | 'link';
	btnStyle?: string;
	btnLabel?: string;
	btnSecondStyle?: string;
	btnSecondLabel?: string;
	btnInlineLabel?: string;
	btnInlineAction?: string | Function;
	btnInlineStyle?: string;
	btnInlineIcon?: string;
	title?: string;
	redirect?: string;
	redirectOnClose?: boolean;
}

export const togglePopup = (cms, modalContent: Modal) => {
	const toggleModal = Object.assign({}, resetModal(cms), modalContent);
	store.state.modal = toggleModal;
};

const resetModal = (cms): Modal => {
	const modal: Modal = {
		// setup fail modal as default
		show: true,
		id: 'none',
		track: false,
		trackToken: undefined,
		errorType: undefined,
		productName: undefined,
		stepName: undefined,
		trackMsg: undefined,
		title: cms.failTitle,
		content: cms.failText,
		btnType: 'button',
		btnStyle: cms.btnStyle,
		btnLabel: cms.btnLabel,
		btnSecondStyle: cms.btnSecondStyle,
		btnSecondLabel: cms.btnSecondLabel,
		btnInlineLabel: undefined,
		btnInlineAction: undefined,
		btnInlineStyle: cms.btnStyle,
		btnInlineIcon: undefined,
		redirect: undefined,
		redirectOnClose: true,
	};
	return modal;
};

export const modalAlert = (): Modal => {
	const modal: Modal = {
		show: true,
		id: 'none',
		btnLabel: undefined,
	};
	return modal;
};

export const modalWithExit = (cms): Modal => {
	const modal: Modal = {
		show: true,
		id: 'none',
		btnLabel: undefined,
		redirectOnClose: false,
	};
	if (cms.btnCallMeUpEnabled) {
		modal.btnLabel = cms.btnLabelClose;
		modal.redirect = cms.btnRedirectUrlClose;
	}
	return modal;
};

export const ModalSubmitToggle = (cms, trackToken: string): void => {
	const modal = modalWithExit(cms);
	modal.id = 'error';
	modal.track = true;
	modal.trackToken = trackToken;
	togglePopup(cms, modal);
};

export const modalDamagesToggle = (cms): void => {
	const modal = modalWithExit(cms);
	modal.title = cms.damagesPopupTitle;
	modal.content = cms.damagesPopupContent;
	modal.id = 'skader';
	togglePopup(cms, modal);
};

export const modalRkiToggle = (cms): void => {
	const modal = modalWithExit(cms);
	modal.title = cms.rkiPopupTitle;
	modal.content = cms.rkiPopupContent;
	modal.id = 'rki';
	togglePopup(cms, modal);
};

export const modalHttp250Toggle = (cms, status: number, trackToken: string, calculator: Calculator): void => {
	const modal = modalAlert();
	modal.content = cms.defaultCalcErrorContent;
	modal.track = true;
	modal.id = status === 250 ? 'calcWarning' : 'calcError';
	modal.title = status === 250 ? cms.defaultCalcErrorTitle : 'Der er desværre sket en fejl';
	modal.trackToken = trackToken;
	modal.errorType = status ? status + '' : undefined;

	if (status !== 250) {
		modal.btnLabel = cms.btnLabelClose;
		modal.redirect = cms.btnRedirectUrlClose;
		modal.redirectOnClose = false;
	}

	if (cms.useBtnInlineFail) {
		modal.btnInlineLabel = cms.btnInlineFailLabel;
		modal.btnInlineStyle = cms.btnInlineFailStyle;
		modal.btnInlineIcon = cms.btnInlineIcon;
		modal.btnInlineAction = () => {
			calculator.getCalculations();
		};
	}
	togglePopup(cms, modal);
};

export const toggleModalSplashCampaign = (product: string, cms: BuyInsuranceSettings, activate?: boolean) => {
	const productNameDisplay = capitalizeFirstLetter(stripProductIndex(product));

	const modal = modalAlert();

	modal.id = 'dependentSplash';
	modal.show = true;
	modal.btnSecondLabel = 'Ok';
	modal.track = false;
	if (activate) {
		modal.title = cms.campaignSplashAlertTitleActivate.replaceAll('{{product}}', productNameDisplay);
		modal.content = cms.campaignSplashAlertContentActivate.replaceAll('{{product}}', productNameDisplay);
	} else {
		modal.title = cms.campaignSplashAlertTitle.replaceAll('{{product}}', productNameDisplay);
		modal.content = cms.campaignSplashAlertContent.replaceAll('{{product}}', productNameDisplay);
	}

	togglePopup(cms, modal);
};
