import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "button-container icon-btn text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_buttonComponent = _resolveComponent("buttonComponent")!
  const _component_modalComponent = _resolveComponent("modalComponent")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_modalComponent, {
        key: 0,
        padding: "0",
        id: _ctx.modal.id,
        dismissible: true,
        trackingEnabled: false,
        useModalTrigger: false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", {
            class: "h2 text-center",
            innerHTML: _ctx.modal.title
          }, null, 8, _hoisted_1)
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              innerHTML: _ctx.modal.content
            }, null, 8, _hoisted_3),
            (_ctx.modal.btnInlineLabel)
              ? (_openBlock(), _createBlock(_component_buttonComponent, {
                  key: 0,
                  btnClass: _ctx.modal.btnInlineStyle,
                  label: _ctx.modal.btnInlineLabel,
                  icon: _ctx.getInlineIcon(),
                  onButtonComponent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInlineAction()))
                }, null, 8, ["btnClass", "label", "icon"]))
              : _createCommentVNode("", true)
          ])
        ]),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.modal.btnSecondLabel)
              ? (_openBlock(), _createBlock(_component_buttonComponent, {
                  key: 0,
                  btnClass: _ctx.modal.btnSecondStyle,
                  btnType: "button",
                  label: _ctx.modal.btnSecondLabel,
                  onButtonComponent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
                }, null, 8, ["btnClass", "label"]))
              : _createCommentVNode("", true),
            (_ctx.modal.btnLabel)
              ? (_openBlock(), _createBlock(_component_buttonComponent, {
                  key: 1,
                  "data-mainbtn": "",
                  btnClass: _ctx.modal.btnStyle,
                  btnType: _ctx.modal.btnType,
                  label: _ctx.modal.btnLabel,
                  target: _ctx.getTarget(),
                  href: _ctx.getHrefType()
                }, null, 8, ["btnClass", "btnType", "label", "target", "href"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}