import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { App } from 'vue';
import { Router } from 'vue-router';
import { initAppInsights } from './logging/ApplicationInsightLog';

const createAppInsights = (options: AppInsightsOptions) => {
	const dataAppInsightsKey = document.querySelector('#app')?.getAttribute('data-appinsights-key') ?? '';
	const applicationInsights = new ApplicationInsights({
		config: {
			instrumentationKey: options.instrumentationKey ?? dataAppInsightsKey,
			//enabling this causes double tracking of a pageview
			enableAutoRouteTracking: false,
		},
	});

	applicationInsights.loadAppInsights();
	initAppInsights(applicationInsights);
	return applicationInsights;
};

export const install = (app: App, options: AppInsightsOptions) => {
	const appInsights = createAppInsights(options);

	options.router.afterEach(async (to, from) => {
		//this is required for the DXP billing
		appInsights.trackPageView({ name: document.title, refUri: from.fullPath });
	});
	app.provide('appInsights', appInsights);
};

export interface AppInsightsOptions {
	instrumentationKey?: string;
	router: Router;
}

export default install;
