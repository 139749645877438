import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoTravel,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
// list and order of steps
export const STEPS: Array<string> = [
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export class TravelModel extends CommonModel implements Model {
	public constructor() {
		super(STEPS, InsurancesProductEnum.REJSE_FORSIKRING);
	}

	public personInfo = new PersonInfoTravel();

	public static mock(model: TravelModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			model.personInfo.isOldestInHousehold = 'nej';
			model.personInfo.oldestInHouseholdAge = '66';

			mockAdditionalInfo(model);

			mockContactInformation(model);
		}
	}
}
