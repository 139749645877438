import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { AccidentChildrenSettings } from '../AccidentChildrenSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { Validator } from '../../services/ValidationService';

@Options({
	name: 'PersonInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: AccidentChildrenSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;
	public defaultZip: string = null;
	public readonlyZip: string = null;

	public created() {
		const zipParam = (this.$route.query.zipCode as string) || (this.$route.query.zipcode as string);
		if (zipParam) {
			this.defaultZip = zipParam;
		}
	}

	public mounted() {
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if ((!this.model.personInfo.zipCode || customerCalculation.hasCustomerData) && this.cms.focusFirstField) {
			// wait for transition to begin showing zipcode field
			setTimeout(() => {
				const input = document.querySelector('.vue3-autocomplete-container input') as any;
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout + 500);
		}
		if (this.model.multipleProducts) {
			const commonData = store.getters.getCalculatorCommonModel;
			if (commonData.personInfo.zipCode && commonData.personInfo.zipName) {
				this.readonlyZip = this.model.personInfo.zipCode + ' ' + this.model.personInfo.zipName;
			}
		}
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public zipSearch(data) {
		if (data.matchText) {
			this.model.personInfo.zipCode = data.zipCode;
			this.model.personInfo.zipName = data.zipName;
			this.model.pristineStep.set(this.card.name, true);
			return;
		}
		this.model.personInfo.zipCode = undefined;
		this.model.personInfo.zipName = undefined;
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
