export const UPDATE_CONTEXT = 'epiContext/UPDATE_CONTEXT';

export interface EpiContextStateContainer {
	epiContext: EpiContextState;
}

export interface EpiContextState {
	inEditMode: boolean;
	isEditable: boolean;
}

const state: EpiContextState = {
	inEditMode: false,
	isEditable: false,
};

const mutations = {
	[UPDATE_CONTEXT](state: EpiContextState, newContext: EpiContextState) {
		state.isEditable = newContext.isEditable;
		state.inEditMode = newContext.inEditMode;
	},
};

export default {
	state,
	mutations,
};
