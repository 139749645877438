import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'SegmentedControlComponent',
	props: {
		id: { type: String, default: Math.random() + '' },
		options: Object,
		labels: Object,
		preSelected: String,
		theme: String,
		controlsPerRow: { type: Number, default: 2 },
	},
})
export default class SegmentedControlComponent extends Vue {
	id: string;
	options: Array<string>;
	labels: Array<string>;
	preSelected: string;
	theme: string;
	controlsPerRow: number;
	selectedControl = -1;
	chunks: Array<Array<number>> = [[]];
	// LIFECYCLE
	public created() {
		this.handleChunks();
	}
	public mounted() {
		if (this.preSelected && this.preSelected !== '' /**&& this.selectedControl === -1*/) {
			this.selectedControl = this.options.findIndex((option) => option + '' === this.preSelected);
		}
	}

	// COMPUTED
	// METHODS
	private handleChunks(): void {
		this.options.forEach((elem, inx) => {
			if (inx % this.controlsPerRow === 0 && inx !== 0) {
				const arr = [];
				arr.push(inx);
				this.chunks.push(arr);
			} else {
				this.chunks[this.chunks.length - 1].push(inx);
			}
		});
	}
	public selected(inx: number): void {
		if (this.selectedControl === inx) {
			return;
		}
		this.selectedControl = inx;
		this.$emit('SegmentedComponent', {
			id: this.id,
			value: this.value(),
		});
	}
	private isValid(): boolean {
		return this.selectedControl > -1;
	}
	private value(): string {
		return this.isValid() ? this.options[this.selectedControl] : undefined;
	}
}
