import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  role: "banner",
  class: "page-header",
  ref: "navOverlay"
}
const _hoisted_2 = { class: "page-header__content" }
const _hoisted_3 = { class: "primary-navigation__brand" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["onClick", "aria-controls"]
const _hoisted_8 = ["aria-label", "id"]
const _hoisted_9 = ["data-gtm-url", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkComponent = _resolveComponent("LinkComponent")!

  return (!_ctx.isLoggedInAsApp())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_LinkComponent, {
              "data-gtm-action": "menu click",
              "data-gtm-label": "Menu logo",
              "data-gtm-url": _ctx.trackingUrl,
              class: "primary-navigation__brand__link",
              to: _ctx.model[0]?.menuPointLink?.url,
              "aria-label": "Til startside"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "primary-navigation__brand__icon",
                  src: _ctx.logoUrl,
                  alt: ""
                }, null, 8, _hoisted_4)
              ]),
              _: 1
            }, 8, ["data-gtm-url", "to"])
          ]),
          _createElementVNode("nav", {
            class: "primary-navigation",
            "aria-label": _ctx.menuLabel
          }, [
            _createElementVNode("ul", {
              class: "primary-navigation__list",
              role: "menubar",
              "aria-label": _ctx.menuLabel
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (menuItem, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  role: "none",
                  key: index,
                  class: "primary-navigation__item",
                  ref_for: true,
                  ref: `sub-menu-${menuItem.title}`
                }, [
                  (_ctx.hasSubmenu(menuItem))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "primary-navigation__link primary-navigation__link--toggle",
                        onClick: ($event: any) => (_ctx.toggleMenu(menuItem.title)),
                        ref_for: true,
                        ref: `toggle-${menuItem.title}`,
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                        "aria-controls": `sub-menu-${menuItem.title}`
                      }, _toDisplayString(menuItem.title), 9, _hoisted_7))
                    : (_openBlock(), _createBlock(_component_LinkComponent, {
                        key: 1,
                        class: _normalizeClass(["primary-navigation__link", { 'active-child active': index === 0 }]),
                        role: "menuitem",
                        to: menuItem?.menuPointLink?.url,
                        "data-gtm-action": "menu click",
                        "data-gtm-label": `Menu ${menuItem.title}`,
                        "data-gtm-url": _ctx.trackingUrl
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            class: _normalizeClass('primary-navigation__link__icon__' + index)
                          }, null, 2),
                          _createTextVNode(" " + _toDisplayString(menuItem.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "to", "data-gtm-label", "data-gtm-url"])),
                  (_ctx.hasSubmenu(menuItem))
                    ? (_openBlock(), _createElementBlock("ul", {
                        key: 2,
                        class: "sub-navigation",
                        "aria-label": menuItem.title,
                        id: `sub-menu-${menuItem.title}`
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.menuPoints, (subRoute, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            role: "none",
                            key: index,
                            class: "sub-navigation__item"
                          }, [
                            _createVNode(_component_LinkComponent, {
                              class: "sub-navigation__link",
                              to: subRoute.menuPointLink?.url,
                              innerHTML: subRoute.title,
                              role: "menuitem",
                              "data-gtm-action": "menu click",
                              "data-gtm-label": `Menu ${subRoute.title}`,
                              "data-gtm-url": _ctx.trackingUrl
                            }, null, 8, ["to", "innerHTML", "data-gtm-label", "data-gtm-url"])
                          ]))
                        }), 128))
                      ], 8, _hoisted_8))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ], 8, _hoisted_6)
          ], 8, _hoisted_5),
          _createElementVNode("a", {
            "data-gtm-action": "menu click",
            "data-gtm-label": "Menu log ud",
            "data-gtm-url": _ctx.trackingUrl,
            href: _ctx.logoutUrl(),
            class: _normalizeClass(['primary-navigation__logout', 'btn-outline-secondary', 'mb-0'])
          }, " Log ud ", 8, _hoisted_9)
        ])
      ], 512))
    : _createCommentVNode("", true)
}