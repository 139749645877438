import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { FamilySettings } from '../FamilySettings';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { jaNejArray, Validator, ValuePair } from '../../services/ValidationService';
import { mapDawaAddress, resetAddress } from '@/views/calculators/services/addressMappingService';
import { modalWithExit, togglePopup } from '../../services/AlertService';

@Options({
	name: 'PersonInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: FamilySettings; // settings from CMS (store)

	public jaNejArray: Array<ValuePair> = jaNejArray;
	public fieldValidator: FieldValidator = Validator;
	public defaultAddress: string = null;
	public readonlyAge: string = null;
	public readonlyAddress: string = null;
	public readonlyFamilyMemberCount: string = null;

	public created() {
		const addressParam = this.$route.query.address as string;
		if (addressParam) {
			this.defaultAddress = addressParam;
		}
	}

	public mounted() {
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if ((!this.model.personInfo.address || customerCalculation.hasCustomerData) && this.cms.focusFirstField) {
			// wait for transition to begin showing address field
			setTimeout(() => {
				const input = document.querySelector('.vue3-autocomplete-container input') as any;
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout + 500);
		}
		if (this.model.multipleProducts) {
			const commonModel = store.getters.getCalculatorCommonModel;
			if (commonModel.personInfo.address) {
				this.readonlyAddress = this.model.personInfo.address;
			}
			if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
				this.readonlyAge = this.model.personInfo.customerAge;
			}
			if (commonModel.personInfo.familyMemberCount) {
				this.readonlyFamilyMemberCount = this.model.personInfo.familyMemberCount;
			}
		}

		if (
			!this.readonlyAge &&
			customerCalculation.hasCustomerData &&
			this.fieldValidator.isValidAge(customerCalculation?.customer?.personInfo?.customerAge)
		) {
			this.readonlyAge = customerCalculation.customer.personInfo.customerAge;
		}

		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public addressSearch(address) {
		if (address.matchText) {
			mapDawaAddress(this.model.personInfo, address);
			this.model.pristineStep.set(this.card.name, true);
			return;
		}
		resetAddress(this.model.personInfo);
	}

	public familyMemberSelected(result: any) {
		if (result && result.selectedValue) {
			this.model.personInfo.familyMemberCount = result.selectedValue;
			const inx = this.familyMemberList.indexOf(result.selectedValue);
			this.model.personInfo.familyMemberCountId =
				this.model.calculation.abCalc.familyMemberCountOptions[inx].value;

			if (this.model.personInfo.familyMemberCountId < 2) {
				setTimeout(() => {
					const modal = modalWithExit(this.cms);
					modal.title = 'Ingen børn i husstand';
					modal.content =
						'Du har oplyst at der er @personCount i jeres husstand. Ret venligst antal personer i jeres husstand, så alle personer er inkluderet'.replace(
							'@personCount',
							this.model.personInfo.familyMemberCount
						);
					modal.id = 'personCountError';
					togglePopup(this.cms, modal);
				}, 550);
			}

			return;
		}

		this.model.personInfo.familyMemberCount = undefined;
		this.model.personInfo.familyMemberCountId = undefined;
	}

	get familyMemberList(): Array<string> {
		const memberListFormatted = [];
		this.model.calculation.abCalc.familyMemberCountOptions.forEach((element) => {
			memberListFormatted.push(element.key);
		});
		return memberListFormatted;
	}

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		if (name === 'thatchedRoof') {
			this.model.personInfo.thatchedRoof = evt.value;
			nextTick(() => {
				// wait for transition to begin showing customerAge field
				setTimeout(() => {
					const input = document.getElementById('customerAge');
					if (input) {
						input.focus();
					}
				}, 300);
			});
		}
	}
}
