import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFieldComponent = _resolveComponent("InputFieldComponent")!
  const _component_bulletListComponent = _resolveComponent("bulletListComponent")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_InputFieldComponent, {
      class: _normalizeClass([
				{ 'modal-input': !_ctx.visibleError && !_ctx.readonlyValue },
				{ 'modal-input__selected': _ctx.selected && !_ctx.readonlyValue },
			]),
      key: _ctx.inputKey,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      id: _ctx.id + 1,
      inputValue: _ctx.inputValue,
      name: _ctx.name,
      required: true,
      readonlyValue: _ctx.readonlyValue,
      visibleError: _ctx.visibleError,
      errorMsgOverride: _ctx.errorMsgOverride,
      autocomplete: "off",
      onInputFocus: _ctx.open
    }, null, 8, ["class", "label", "placeholder", "id", "inputValue", "name", "readonlyValue", "visibleError", "errorMsgOverride", "onInputFocus"])),
    _createVNode(_component_ModalComponent, {
      padding: "0",
      id: _ctx.id,
      dismissible: true,
      trackingEnabled: false,
      useModalTrigger: false,
      top: "50%"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_bulletListComponent, {
          type: "none",
          selectable: true,
          selectedValue: _ctx.inputValue,
          innerData: _ctx.innerData,
          onBulletListComponent: _ctx.selectItem
        }, null, 8, ["selectedValue", "innerData", "onBulletListComponent"])
      ]),
      _: 1
    }, 8, ["id"])
  ]))
}