import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { DogSettings } from '../DogSettings';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { jaNejArray, ValuePair } from '../../services/ValidationService';
import { modalWithExit, togglePopup } from '../../services/AlertService';

@Options({
	name: 'AdditionalQuestionsInfoStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>,
		isValid: Boolean,
	},
})
export default class AdditionalQuestionsInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean;

	public model!: any; // datastructure for user input (store)
	public cms!: DogSettings; // settings from CMS (store)

	public jaNejArray: Array<ValuePair> = jaNejArray;

	public handleRadioClick(evt: any) {
		const name = evt.name ? evt.name : evt.id;
		switch (name) {
			case 'vaccine':
				this.model.dogInfo.vaccine = evt.value;
				if (evt.value === 'nej') {
					this.handleModal(
						'vaccine',
						this.cms.vaccinePopTitle,
						this.model.dogInfo.dogCount === 2 ? this.cms.vaccinePopContentMulti : this.cms.vaccinePopContent
					);
				}

				break;
			case 'healthy':
				this.model.dogInfo.healthy = evt.value;
				if (evt.value === 'nej') {
					this.handleModal(
						'healthy',
						this.cms.healthyPopTitle_,
						this.model.dogInfo.dogCount === 2
							? this.cms.healthyPopContent_Multi
							: this.cms.healthyPopContent_
					);
				}

				break;
			case 'beenUnhealthy':
				this.model.dogInfo.beenUnhealthy = evt.value;
				if (evt.value === 'ja') {
					this.handleModal(
						'beenUnhealthy',
						this.cms.beenUnhealthyPopTitle,
						this.model.dogInfo.dogCount === 2
							? this.cms.beenUnhealthyPopContentMulti
							: this.cms.beenUnhealthyPopContent
					);
				}

				break;
			case 'vet':
				this.model.dogInfo.vet = evt.value;
				if (evt.value === 'ja') {
					this.handleModal(
						'vet',
						this.cms.vetPopTitle,
						this.model.dogInfo.dogCount === 2 ? this.cms.vetPopContentMulti : this.cms.vetPopContent
					);
				}
				break;
		}
	}

	private handleModal(id: string, title: string, content: string) {
		const modal = modalWithExit(this.cms);
		modal.title = title;
		modal.content = content;
		modal.id = id;
		togglePopup(this.cms, modal);
	}
}
