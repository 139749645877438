import { Options, Vue } from 'vue-class-component';
import {
	UpSaleCampaignData,
	UpSaleProduct,
	upSaleCampaignName,
	updateUpSaleCampaign,
} from '../../services/campaignService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';
import { mapState } from 'vuex';
import BuyInsuranceSettings from '../../BuyInsuranceSettings';
import { CalculatorCommonModel } from '@/store/modules/calculatorContext';
import {
	PlusCustomerType,
	plusCustomerDiscount,
	resetCampaignStorageAll,
	stripProductIndex,
	uniqueStringArray,
} from '../../services/ProductService';
import { capitalizeFirstLetter } from '../../services/UiStepService';
import { formatPercent, formatNumber } from '../../services/ValidationService';
import store from '@/store/store';
import {
	getSecondaryProductsCount,
	getSuggestedProduct,
	hasPrimaryProduct,
	Suggestion,
} from '../../services/SuggestProductService';
import { defaultConfig } from '@/cms/api/ApiConfig';
import AxiosService from '../../services/axiosService';
import { TileBlock } from '@/cms/definitions/content-types';
import { modalAlert, togglePopup } from '../../services/AlertService';

@Options({
	name: 'UpSaleComponent',
	components: {},
	props: {},
	computed: mapState<any>({
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class UpSaleComponent extends Vue {
	cms!: BuyInsuranceSettings;

	public showExistingProducts = false;
	public upSaleCampaignData: UpSaleCampaignData = null;
	public upSaleProducts: UpSaleProduct[] = [];
	public commonModel: CalculatorCommonModel = null;
	public showOrderDetails = false;
	public init = false;
	public currentInsurance = '';
	public suggestedProduct: string = null;
	public productList: string[] = [];
	public tileBlocks: TileBlock[] = [];
	private suggestion: Suggestion = null;
	private existingDiscountAddOn = 0;
	private basketTiles: TileBlock[];

	public async mounted() {
		try {
			if (store.state.missingCalculationsInBasket) {
				store.state.upSaleCampaignData = undefined;
				return;
			}
			this.upSaleCampaignData = store.state.upSaleCampaignData;

			if (!this.upSaleCampaignData || this.upSaleCampaignData.hideUpsalePk3) {
				return;
			}
			this.upSaleProducts = this.upSaleCampaignData.upSaleProducts;

			let hasValidUpSaleProduct = false;
			const length = this.upSaleProducts.length;
			let highestPkLevel = PlusCustomerType.PK0; //'pk0';
			this.upSaleProducts.forEach((product, inx) => {
				if (product.validUpSaleProduct) {
					hasValidUpSaleProduct = true;
				}

				highestPkLevel = highestPkLevel >= product.currentLevel ? highestPkLevel : product.currentLevel;

				if (inx === 0 && length === 1) {
					this.currentInsurance = product.productNameDisplay;
					return;
				}

				if (inx < length - 1) {
					this.currentInsurance += product.productNameDisplayMobile + '- ';
				}

				if (inx === length - 1) {
					this.currentInsurance += 'og ' + product.productNameDisplay;
				}
			});

			if (!hasValidUpSaleProduct) {
				return;
			}

			// ensure all products are on the same currentLevel - can differ if person added products from basket during upsale calculation
			this.upSaleProducts.forEach((product) => {
				product.currentLevel = highestPkLevel;
			});

			this.commonModel = this.upSaleCampaignData.calculatorCommonModel;
			this.setProductList();
			const floorProperty = this.commonModel.personInfo.floorNr;
			this.suggestion = getSuggestedProduct(this.cms, floorProperty, this.productList);
			if (!this.suggestion.suggestProductText) {
				return;
			}

			this.existingDiscountAddOn =
				this.suggestion.discount - plusCustomerDiscount.get(this.upSaleProducts[0].currentLevel);

			if (this.existingDiscountAddOn === 0) {
				// KLK user changed recommended product to a non PK type
				return;
			}
			this.suggestedProduct = capitalizeFirstLetter(this.suggestion.suggestProductText);

			this.showExistingProducts = this.upSaleProducts[0].currentLevel !== PlusCustomerType.PK3;

			this.cms.contactCompleteOffer = false;

			await this.initTiles();
			this.init = true;
		} catch (error) {
			exception(error);
			this.suggestedProduct = null;
		}
	}

	public suggestText() {
		try {
			let txt: string;
			if (this.showExistingProducts) {
				txt =
					this.replaceTokens(this.cms.mainTxtUpSaleMoreDiscount) +
					this.replaceTokens(this.cms.txtProductUpSale);
			} else {
				txt = this.replaceTokens(this.cms.mainTxtUpSale);
			}
			return txt;
		} catch (error) {
			exception(error);
		}
	}

	private replaceTokens(text: string): string {
		const upSaleCampaignDiscount = this.upSaleCampaignData.upSaleProducts[0].upSaleCampaignDiscount;
		const totalDiscountSuggestedProduct = formatPercent.format(this.suggestion.discount + upSaleCampaignDiscount);

		const saveOnExistingProducts = formatNumber.format(this.getTotalSavingsAmount(this.suggestion.plusCustomer));

		return text
			.replaceAll('{{suggestedProduct}}', this.suggestedProduct)
			.replaceAll('{{totalDiscountSuggestedProduct}}', totalDiscountSuggestedProduct)
			.replaceAll('{{existingDiscountAddOn}}', formatPercent.format(this.existingDiscountAddOn))
			.replaceAll('{{currentInsurance}}', this.currentInsurance)
			.replaceAll('{{saveOnExistingProducts}}', saveOnExistingProducts);
	}

	public getProductHighlights(product: UpSaleProduct) {
		const existingDiscountAddOn = formatPercent.format(this.existingDiscountAddOn);
		const txt = this.cms.singleProductTxtUpSale
			.replaceAll('{{productNameDisplay}}', product.productNameDisplayMobile)
			.replaceAll('{{packageName}}', product.packageName)
			.replaceAll('{{existingDiscountAddOn}}', existingDiscountAddOn);
		return txt;
	}

	public getProductHighlightsAmount(product: UpSaleProduct) {
		const previousPrice = product[product.currentLevel].price;
		const discountPrice = product[this.suggestion.plusCustomer].price;
		if (previousPrice > discountPrice) {
			const txt = this.cms.productAmountTxtUpSale
				.replaceAll('{{previousPrice}}', formatNumber.format(previousPrice))
				.replaceAll('{{discountPrice}}', formatNumber.format(discountPrice));
			return txt;
		} else {
			return this.cms.notCalculatedUpSaleProduct;
		}
	}

	public getProductHighlightsSummed() {
		if (this.upSaleProducts.length > 1) {
			const saveOnExistingProducts = formatNumber.format(
				this.getTotalSavingsAmount(this.suggestion.plusCustomer)
			);
			const txt = this.cms.productTotalAmountNumberUpSale.replaceAll(
				'{{saveOnExistingProducts}}',
				saveOnExistingProducts
			);
			return txt;
		}

		return undefined;
	}
	public navigateToProduct() {
		// goto store and find upsale basket, and add these products
		const selectedProducts = store.getters.getSelectableGroup(upSaleCampaignName);
		const selectedProductsTrimmed: string[] = [];

		selectedProducts.forEach((productId) => {
			const name = stripProductIndex(productId);
			selectedProductsTrimmed.push(name);
		});

		const completeProductList: string[] = [...selectedProductsTrimmed, ...this.productList];
		const hasIndbo = hasPrimaryProduct(completeProductList, this.cms);
		const hasSecondary = getSecondaryProductsCount(completeProductList) > 0;

		if (this.upSaleProducts[0].currentLevel === PlusCustomerType.PK0 && (!hasIndbo || !hasSecondary)) {
			this.indboUlykkePopup();
			return;
		}
		const url = '/gotoproduct?products=' + selectedProductsTrimmed.join(', ');

		updateUpSaleCampaign(this.suggestion.plusCustomer, this.upSaleCampaignData);

		resetCampaignStorageAll(this.cms.AllCalculationProducts);

		store.state.showSpinner = true;
		setTimeout(() => {
			location.href = url;
		}, 200);
	}

	private indboUlykkePopup() {
		const modal = modalAlert();
		modal.title = this.cms.upsaleAlertDiscountTitle;
		modal.content = this.cms.upsaleAlertDiscountContent;
		modal.id = 'upsale missing discount';

		togglePopup(this.cms, modal);
	}

	public getSavingsAmount(product: UpSaleProduct, plusCustomer: PlusCustomerType): number {
		const previousPrice = product[product.currentLevel].price;
		const discountPrice = product[plusCustomer].price;
		return previousPrice - discountPrice;
	}

	public getTotalSavingsAmount(plusCustomer: PlusCustomerType): number {
		let totalSavingsAmount = 0;
		this.upSaleProducts.forEach((product) => {
			const productSaving = this.getSavingsAmount(product, plusCustomer);
			if (productSaving > 0) {
				totalSavingsAmount += this.getSavingsAmount(product, plusCustomer);
			}
		});
		return totalSavingsAmount;
	}

	public toggleOrderDetails() {
		this.showOrderDetails = !this.showOrderDetails;
	}

	public setProductList() {
		const products = this.upSaleCampaignData.calculatorCommonModel.personInfo.existingAlmBrandProducts || [];

		products.forEach((name) => {
			this.productList.push(name.trim().toLocaleLowerCase());
		});

		this.upSaleProducts.forEach((product) => {
			this.productList.push(product.productName);
		});

		this.productList = uniqueStringArray(this.productList);
	}

	private async initTiles() {
		const axiosService = new AxiosService();
		const params = {
			contentUrl: 'forsikringer',
			matchExact: true,
		};

		const tiles = await axiosService.getRetry(defaultConfig.apiUrl, params);
		this.basketTiles = tiles.data.mainArea;

		this.upSaleProducts.forEach((product) => {
			const tileBlock = this.findProductTile(product.productName);
			tileBlock.selectGroup = upSaleCampaignName;
			tileBlock.disabled = true;
			tileBlock.selectDisabled = true;

			const saveOnExistingProduct = this.getSavingsAmount(product, this.suggestion.plusCustomer);
			if (saveOnExistingProduct > 0) {
				tileBlock.splashTxt = `<strong>-${formatNumber.format(saveOnExistingProduct)}</strong> kr. årligt`;
			} else {
				tileBlock.splashTxt = undefined; // remove possible other campaign splashes
			}

			this.tileBlocks.push(tileBlock);
		});

		const upSaleCampaignDiscount = this.upSaleCampaignData.upSaleProducts[0].upSaleCampaignDiscount;

		if (this.suggestion.suggestMultipleProducts) {
			this.suggestion.suggestProducts.forEach((product) => {
				const suggestedTile = this.findProductTile(product);
				suggestedTile.selectGroup = upSaleCampaignName;
				suggestedTile.splashTxt = `<div>Spar</div><div><b>${formatPercent.format(this.suggestion.discount + upSaleCampaignDiscount)}</b></div>`;
				this.tileBlocks.push(suggestedTile);
			});
		} else {
			const suggestedTile = this.findProductTile(this.suggestion.suggestProductText);
			suggestedTile.selectGroup = upSaleCampaignName;
			suggestedTile.splashTxt = `<div>Spar</div><div><b>${formatPercent.format(this.suggestion.discount + upSaleCampaignDiscount)}</b></div>`;
			this.tileBlocks.push(suggestedTile);
		}

		this.basketTiles.forEach((contentItem) => {
			// 	// always add button
			if (contentItem.contentType === 'TileBlock' && contentItem.tileVariant === 'Button') {
				contentItem.selectGroupBtn = upSaleCampaignName;
				contentItem.title = this.cms.btnLabelUpSale;
				contentItem.relativeURL = '';
				contentItem.externalAction = this.navigateToProduct;
				contentItem.ignoreTouch = true;
				this.tileBlocks.push(contentItem);
			}
		});
	}

	private findProductTile(productName: string): TileBlock {
		const tile = this.basketTiles.find(
			(contentItem) => contentItem.contentType === 'TileBlock' && contentItem.selectId.startsWith(productName)
		);
		// clone if there are multiple products
		return { ...tile };
	}
}
