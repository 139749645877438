// import { isWeb, AppSendMessage } from "@/services/environmentService";
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'pageTitleComponent',
	props: {
		preTitle: String,
		mainTitle: String,
		// showTitleInApp: Boolean
	},
})
export default class PageTitleComponent extends Vue {
	preTitle: string;
	mainTitle: string;
	// showTitleInApp: boolean;

	// public created() {
	//     if (this.showTitleInApp)  {
	//         AppSendMessage({ MAIN_TITLE: this.mainTitle });
	//     }
	// }

	public get showTitle() {
		// return (isWeb() || this.showTitleInApp) && (this.preTitle || this.mainTitle);
		return this.preTitle || this.mainTitle;
	}
}
