import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { modalWithExit, togglePopup } from './AlertService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

export interface ValuePair {
	value: string;
	displayValue: string;
}

export const monthYearArray: Array<ValuePair> = [
	{
		value: 'M',
		displayValue: 'Pr. md.',
	},
	{
		value: 'Y',
		displayValue: 'Pr. år',
	},
];

export const jaNejArray: Array<ValuePair> = [
	{
		value: 'ja',
		displayValue: 'Ja',
	},
	{
		value: 'nej',
		displayValue: 'Nej',
	},
];

export const validNowArray: Array<ValuePair> = [
	{
		value: 'ja',
		displayValue: 'Hurtigst muligt',
	},
	{
		value: 'nej',
		displayValue: 'Vælg dato',
	},
];

export const formatNumber: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
} as any);

export const formatPercent: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	style: 'percent',
	minimumFractionDigits: 0,
} as any);

export const Validator: FieldValidator = new FieldValidator('none');

export const getShowReceipt = (comp) => {
	return comp.componentInit && comp.cardReceipt && (comp.model?.showReceipt || comp.checkout);
};

export const isValidAlmbrandProducts = (model) => {
	let ok = model.personInfo.almbrandCustomer !== undefined;
	if (ok) {
		if (model.personInfo.almbrandCustomer === 'ja') {
			ok = model.personInfo.existingAlmBrandProducts?.length > 0 || !model.showExistingAlmBrandProducts;
		}
	}
	return ok;
};

export const isValidPersonInfo = (model): boolean => {
	const okPerson =
		model.personInfo.zipCode &&
		model.personInfo.zipName &&
		Validator.isValidAge(model.personInfo.customerAge) &&
		isValidAlmbrandProducts(model);
	if (okPerson) {
		model.personInfo.customerAge = model.personInfo.customerAge.trim().replace(/\D/g, '');
		model.personInfo.subtitle = `${model.personInfo.customerAge} år, ${model.personInfo.zipCode} ${model.personInfo.zipName}`;
	}
	return okPerson;
};

export const isValidOtherPersonInfo = (model): boolean => {
	const okPerson =
		model.personInfo.zipCode &&
		model.personInfo.zipName &&
		Validator.isValidAge(model.personInfo.otherCustomerAge) &&
		isValidAlmbrandProducts(model);
	if (okPerson) {
		model.personInfo.otherCustomerAge = model.personInfo.otherCustomerAge.trim().replace(/\D/g, '');
		model.personInfo.subtitle = `${model.personInfo.otherCustomerAge} år, ${model.personInfo.zipCode} ${model.personInfo.zipName}`;
	}
	return okPerson;
};

export const isValidContactInfo = (model, cms): boolean => {
	if (cms.customerAddressRequired && model.personInfo.address === undefined) {
		return false;
	}

	if (model.contact_information.customerNo) {
		// only validate Email, phone and customer accept
		return (
			!!(model.contact_information.accept && Validator.isValidEmail(model.contact_information.email)) &&
			Validator.isValidPhone(model.contact_information.phone)
		);
	}
	const okContact =
		model.contact_information.accept &&
		Validator.isValidName(model.contact_information.name) &&
		Validator.isValidPhone(model.contact_information.phone) &&
		Validator.isValidEmail(model.contact_information.email) &&
		Validator.isValidCpr(model.contact_information.cpr);
	return !!okContact;
};

export const isValidAdditionalInfo = (skipInsuranceFields: boolean, model, cms): boolean => {
	let ok = true;
	if (!skipInsuranceFields) {
		ok = !!(model.additionalInfo.existInsurance && model.additionalInfo.validNow);
		if (!ok) {
			return ok;
		}
		if (model.additionalInfo.existInsurance === 'ja') {
			ok = Validator.isValidExistingInsurance(model.additionalInfo.existingInsurance);
			if (!ok) {
				return ok;
			}
		}
	}
	if (!model.additionalInfo.validNow) {
		return false;
	}
	if (model.additionalInfo.validNow === 'nej') {
		ok = Validator.isValidFromDate(model.additionalInfo.validFromDate);
	}

	if (ok && !model.multipleProducts) {
		// if in basketMode, RKI and damages question will be in another flow
		ok = model.additionalInfo.rki === 'nej';
		if (ok) {
			ok = model.additionalInfo.skader && cms.damagesOk.includes(model.additionalInfo.skader);
		}
	}

	return !!ok;
};

export const isValidPaymentInfo = (model, cms): boolean => {
	if (cms.skipAccountInfo) {
		return model.payment.userSelected && !!model.choosePackage.monthYear;
	}
	return (
		model.payment.userSelected &&
		!!model.choosePackage.monthYear &&
		Validator.isValidRegNo(model.payment.regNo) &&
		Validator.isValidKontoNo(model.payment.kontoNo)
	);
};

export const isValidPackage = (comp): boolean => {
	const ok = !!(comp.model.choosePackage.selectedPackage && comp.model.choosePackage.ownRiskId);
	if (ok && !comp.model.overview.highlights) {
		comp.setOverviewData();
	}
	return ok;
};

export const handleValidCustomerAge = (model, cms): boolean => {
	const ok = Validator.isValidAge(model.personInfo.customerAge);
	if (!ok) {
		const modal = modalWithExit(cms);
		modal.title = cms.customerAgePopupTitle;
		modal.content = cms.customerAgePopupContent;
		modal.id = 'customerAgePopup';
		togglePopup(cms, modal);
	}
	// clean customer age input
	model.personInfo.customerAge = model.personInfo.customerAge.trim().replace(/\D/g, '');
	return ok;
};

/**Checks if calculator has Address supplement  (price * 1.6)*/
export const hasAddressSupplement = (model, cms): boolean => {
	try {
		const zipCitySearch = `${model.personInfo.zipCode} ${model.personInfo.zipName}`.toLocaleLowerCase();
		const zipFound = cms.callUpAddressesBlocks.find((address) => address.zipCity === zipCitySearch);
		if (zipFound) {
			const streetName = model.personInfo.streetName.toLocaleLowerCase();
			const streetFound = zipFound.streets.find((street) => street.name === streetName);
			if (streetFound) {
				if (streetFound.nos) {
					const no = model.personInfo.houseNr.toLocaleLowerCase();
					return streetFound.nos.includes(no);
				}
			} else {
				return false;
			}
			return true;
		}
		return false;
	} catch (error) {
		exception(error);
		return false;
	}
};

export const showValidNowOption = (model): boolean => {
	if (
		model.additionalInfo.existInsurance === 'nej' ||
		(model.additionalInfo.existInsurance === 'ja' && model.additionalInfo.existingInsurance)
	) {
		return true;
	}
	return false;
};
