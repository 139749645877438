import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "popover popover--icon" }
const _hoisted_2 = {
  key: 0,
  class: "popover__box popover__box--lg",
  ref: "popover"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  class: "popover__box__arrow",
  ref: "arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", _mergeProps({
      class: ['popover__toggle', 'popover__toggle--' + _ctx.type, _ctx.buttonClass],
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggle()), ["stop"]))
    }, _ctx.trackingAttributes), [
      _renderSlot(_ctx.$slots, "default")
    ], 16),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "close",
                onTouchstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.touchClose && _ctx.touchClose(...args)))
              }, null, 32),
              _createElementVNode("div", {
                class: "popover__box__inner",
                innerHTML: _ctx.innerData
              }, null, 8, _hoisted_3),
              _renderSlot(_ctx.$slots, "body", { class: "popover" }),
              _createElementVNode("div", _hoisted_4, null, 512)
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}