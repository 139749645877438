import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vertical-app vertical-flow" }
const _hoisted_2 = {
  key: 0,
  class: "row no-gutters"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["onClick", "data-gtm", "data-gtm-click-text"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "vertical-flow__form__step__text" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "vertical-flow__form__step__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CampaignStepComponent = _resolveComponent("CampaignStepComponent")!
  const _component_OverviewBasketStepComponent = _resolveComponent("OverviewBasketStepComponent")!
  const _component_ContactInformationBasketStepComponent = _resolveComponent("ContactInformationBasketStepComponent")!
  const _component_PaymentBasketStepComponent = _resolveComponent("PaymentBasketStepComponent")!
  const _component_ReceiptStepComponent = _resolveComponent("ReceiptStepComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.model?.showHeaderBadge && _ctx.componentInit && !_ctx.showReceipt)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass({ 'vertical-app badge': _ctx.hasBadgeText })
          }, [
            _createElementVNode("div", {
              innerHTML: _ctx.headerBadgeTxt,
              class: _normalizeClass({ 'calculator-badge': _ctx.hasBadgeText })
            }, null, 10, _hoisted_3)
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.componentInit && !_ctx.model?.showReceipt && !_ctx.checkout)
      ? (_openBlock(), _createElementBlock("div", {
          key: _ctx.model.updateAll
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentBlocks, (card, inx) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'step' + inx
            }, [
              _createElementVNode("div", {
                id: card.name,
                class: _normalizeClass([
						'vertical-flow__form__step',
						_ctx.isActiveCard(card.name)
							? 'vertical-flow__form__step--active'
							: 'vertical-flow__form__step--inactive',
						_ctx.isValid(card.name) && !_ctx.isActiveCard(card.name) ? 'vertical-flow__form__step--validated' : '',
					])
              }, [
                _createElementVNode("div", {
                  class: "vertical-flow__form__step__title",
                  onClick: ($event: any) => (_ctx.nextStep(card.name)),
                  "data-gtm": _ctx.cms.dataGtm,
                  "data-gtm-click-text": card.name
                }, [
                  _createElementVNode("div", {
                    class: "vertical-flow__form__step__number",
                    textContent: _toDisplayString(inx + 1)
                  }, null, 8, _hoisted_6),
                  _createElementVNode("div", _hoisted_7, [
                    (card.title)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "main",
                          innerHTML: card.title
                        }, null, 8, _hoisted_8))
                      : _createCommentVNode("", true),
                    ((_ctx.getSubtitle(card.name) && !_ctx.isActiveCard(card.name)) || _ctx.getSubtitle(card.name))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "validation-message",
                          innerHTML: _ctx.getSubtitle(card.name)
                        }, null, 8, _hoisted_9))
                      : _createCommentVNode("", true)
                  ])
                ], 8, _hoisted_5),
                _createVNode(_Transition, { name: "slide-vertical" }, {
                  default: _withCtx(() => [
                    (_ctx.isActiveCard(card.name))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(['vertical-flow__form__step__accordion', card.textCenter ? 'text-center' : ''])
                        }, [
                          _createElementVNode("div", _hoisted_10, [
                            (card.name === _ctx.model.campaign.ID)
                              ? (_openBlock(), _createBlock(_component_CampaignStepComponent, {
                                  key: 0,
                                  card: card,
                                  onNextStep: _ctx.nextStep
                                }, null, 8, ["card", "onNextStep"]))
                              : (card.name === _ctx.steps[0])
                                ? (_openBlock(), _createBlock(_component_OverviewBasketStepComponent, {
                                    key: 1,
                                    card: card,
                                    isValid: _ctx.isValid(card.name),
                                    onBasketPrice: _ctx.handlePrice,
                                    onNextStep: _ctx.nextStep
                                  }, null, 8, ["card", "isValid", "onBasketPrice", "onNextStep"]))
                                : (card.name === _ctx.steps[1])
                                  ? (_openBlock(), _createBlock(_component_ContactInformationBasketStepComponent, {
                                      key: 2,
                                      card: card,
                                      isValid: _ctx.isValid(card.name),
                                      onNextStep: _ctx.nextStep
                                    }, null, 8, ["card", "isValid", "onNextStep"]))
                                  : (card.name === _ctx.steps[2])
                                    ? (_openBlock(), _createBlock(_component_PaymentBasketStepComponent, {
                                        key: 3,
                                        card: card,
                                        isValid: _ctx.isValid(card.name),
                                        onNextStep: _ctx.nextStep
                                      }, null, 8, ["card", "isValid", "onNextStep"]))
                                    : _createCommentVNode("", true)
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_4)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.showReceipt)
      ? (_openBlock(), _createBlock(_component_ReceiptStepComponent, {
          key: 2,
          card: _ctx.cardReceipt,
          usabillaBlock: _ctx.usabillaBlock
        }, null, 8, ["card", "usabillaBlock"]))
      : _createCommentVNode("", true)
  ]))
}