import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { CarSettings } from '../CarSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import { Validator } from '../../services/ValidationService';
import { mapDawaAddress, resetAddress } from '@/views/calculators/services/addressMappingService';
import { CarModel } from '../CarModel';

@Options({
	name: 'PersonInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isNewDriver: Boolean, // wether person has any driving experience
		isValid: Boolean, // wether card is complete
	},
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isNewDriver: boolean; // wether person has any driving experience
	isValid: boolean; // wether card is complete

	public model!: CarModel; // datastructure for user input (store)
	public cms!: CarSettings; // settings from CMS (store)

	public updateCarOwnerYears = 'updateCarOwnerYears';
	public fieldValidator: FieldValidator = Validator;
	public readonlyAge: string = null;
	public defaultAddress: string = null;
	public readonlyAddress: string = null;
	public readonlyCarOwnerYears: string = null;

	public created() {
		const addressParam = this.$route.query.address as string;
		if (addressParam) {
			this.defaultAddress = addressParam;
		}
	}

	public mounted() {
		// this.readonlyCarOwnerYears = 'GOT IT';
		// this.model.carInfo.carOwnerYears = this.readonlyCarOwnerYears;
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if ((!this.model.personInfo.address || customerCalculation.hasCustomerData) && this.cms.focusFirstField) {
			// wait for transition to begin showing address field
			setTimeout(() => {
				const input: HTMLElement = document.querySelector('.vue3-autocomplete-container input');
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout + 500);
		}
		if (this.model.personInfo.customerAge) {
			this.setCarOwnerArray();
		}

		if (this.model.multipleProducts) {
			const commonModel = store.getters.getCalculatorCommonModel;
			if (commonModel.personInfo.address) {
				this.readonlyAddress = this.model.personInfo.address;
			}
			if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
				this.readonlyAge = this.model.personInfo.customerAge;
			}

			const basket = store.getters.getSelectableGroup('basket');
			const activeIndex = store.getters.getActiveCalculatorInx;

			basket.forEach((product) => {
				if (product.includes(this.model.productName) && product !== this.model.productName + activeIndex) {
					const calc = store.getters.getCalculator(product);
					const carOwnerYears = calc?.model?.carInfo.carOwnerYears;
					if (calc?.model?.personInfo && calc?.model?.carInfo) {
						this.handleReadOnlyValues(calc, carOwnerYears);
					}
				}
			});
		}

		if (
			!this.readonlyAge &&
			customerCalculation.hasCustomerData &&
			this.fieldValidator.isValidAge(customerCalculation?.customer?.personInfo?.customerAge)
		) {
			this.readonlyAge = customerCalculation.customer.personInfo.customerAge;
		}

		window.addEventListener('keyup', this.moveOn);
	}

	private handleReadOnlyValues(calc, carOwnerYears) {
		if (carOwnerYears) {
			this.model.carInfo.carOwnerYears = carOwnerYears;
			this.readonlyCarOwnerYears = carOwnerYears;
			this.updateCarOwnerYears += 1;
		}
		if (!this.readonlyAddress && calc.model.personInfo.address) {
			this.model.personInfo.address = calc.model.personInfo.address;
			this.readonlyAddress = this.model.personInfo.address;
		}
		if (!this.readonlyAge && this.fieldValidator.isValidAge(calc.model.personInfo.customerAge)) {
			this.model.personInfo.customerAge = calc.model.personInfo.customerAge;
			this.readonlyAge = this.model.personInfo.customerAge;
		}
	}
	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public addressSearch(address) {
		if (address.matchText) {
			mapDawaAddress(this.model.personInfo, address);

			this.model.pristineStep.set(this.card.name, true);
			if (!this.model.personInfo.customerAge) {
				nextTick(() => {
					// wait for transition to begin showing customerAge field
					setTimeout(() => {
						const input = document.getElementById('customerAge');
						if (input) {
							input.focus();
						}
					}, 300);
				});
			} else {
				this.setCarOwnerArray();
				nextTick(() => {
					setTimeout(() => {
						(document.activeElement as any).blur();
					}, 300);
				});
			}
			return;
		}

		resetAddress(this.model.personInfo);
	}

	public setCarOwnerArray() {
		if (this.fieldValidator.isValidAge(this.model.personInfo.customerAge)) {
			this.model.personInfo.customerAge = this.model.personInfo.customerAge.trim().replace(/\D/g, ''); // clean customerAge input
			const maxCarOwnerYears = parseInt(this.model.personInfo.customerAge) - 17; // if customerAge = 18, then user could have 1 year experience
			this.model.carInfo.carOwnerYearsByAgeArray = this.cms.carOwnerYears.slice(0, maxCarOwnerYears + 1); // since first choice is no experience driving
			if (
				this.model.carInfo.carOwnerYears &&
				!this.model.carInfo.carOwnerYearsByAgeArray.includes(this.model.carInfo.carOwnerYears)
			) {
				this.model.carInfo.carOwnerYears = undefined;
				this.updateCarOwnerYears += 1;
			}
		}
	}

	public selectCarOwnerYears(evt) {
		this.model.carInfo.carOwnerYears = evt.selectedValue;
		if (this.isNewDriver) {
			this.model.additionalInfo.existFormerInsurance = undefined;
			this.model.additionalInfo.existInsurance = undefined;
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
