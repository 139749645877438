import Axios, { AxiosRequestConfig } from 'axios';
import AxiosRetry from 'axios-retry';
import { getReCaptchaToken } from './reCaptchaService';
import { exception } from '@/appinsights/logging/ApplicationInsightLog';

const timeout = 15000;

export default class AxiosService {
	private readonly axiosRetry = AxiosRetry;

	protected axiosRetry3 = Axios.create({
		timeout,
		headers: {
			Accept: '*/*',
			'Accept-Language': 'da',
		},
	});
	private readonly axiosOnce = Axios.create({
		timeout,
		headers: {
			Accept: '*/*',
			'Accept-Language': 'da',
		},
	});
	protected defaultUrl: string;
	private withCredentials = true;
	// Recaptcha DISABLED
	private readonly recaptchaEnabled = false;

	private readonly retryConfig = {
		retries: 2,
		retryDelay: (retryCount: number) => {
			return retryCount * 100; // time interval between retries
		},
		retryCondition: (error) => {
			if (!error?.response?.status) {
				return true;
			}
			return error?.response?.status > 240;
		},
		shouldResetTimeout: true,
	};

	constructor(url?: string) {
		this.defaultUrl = url;

		this.axiosRetry(this.axiosRetry3, this.retryConfig);

		this.axiosRetry3.interceptors.request.use(async (config) => {
			return await this.addHeaders(config);
		});

		this.axiosOnce.interceptors.request.use(async (config) => {
			return await this.addHeaders(config);
		});
	}

	public setWithCredentials(withCredentials: boolean) {
		this.withCredentials = withCredentials;
	}

	public async getQuery(url: string, params): Promise<any> {
		const url_ = url || this.defaultUrl;
		try {
			return await this.axiosOnce.get(url_, this.buildGetParams(params));
		} catch (error) {
			exception(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	public async get(url: string = this.defaultUrl): Promise<any> {
		try {
			return await this.axiosOnce.get(url, this.buildGetParams(undefined));
		} catch (error) {
			exception(error);
			if (error?.response) {
				return error.response;
			}
		}
	}
	public getRetryDefaultUrl(params?: any): Promise<any> {
		return this.getRetry(undefined, params);
	}

	public async getRetry(url: string, params?: any): Promise<any> {
		let response;
		try {
			const url_ = url || this.defaultUrl;
			response = await this.axiosRetry3.get(url_, this.buildGetParams(params));
			return response;
		} catch (error) {
			exception(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	/** default url is used if Url param is undefined */
	public async post(url: string, params?: any): Promise<any> {
		try {
			const url_ = url || this.defaultUrl;
			const response = await this.axiosOnce.post(url_, this.buildPostParams(params));
			return response;
		} catch (error) {
			exception(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	/** default url is used if Url param is undefined */
	public async postRetry(url: string, params?: any): Promise<any> {
		try {
			const url_ = url || this.defaultUrl;
			const response = await this.axiosRetry3.post(url_, this.buildPostParams(params));
			return response;
		} catch (error) {
			exception(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	public async delete(url: string, params?: any): Promise<any> {
		try {
			const url_ = url || this.defaultUrl;
			return await this.axiosOnce.delete(url_, this.buildPostParams(params));
		} catch (error) {
			exception(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	public async put(url: string, params?: any): Promise<any> {
		try {
			const url_ = url || this.defaultUrl;
			return await this.axiosOnce.put(url_, this.buildPostParams(params));
		} catch (error) {
			exception(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	private async addHeaders(config): Promise<AxiosRequestConfig> {
		let recaptchaToken: string;

		if (this.recaptchaEnabled) {
			recaptchaToken = await getReCaptchaToken();
		}

		config.headers = {
			'x-recaptcha-token': recaptchaToken,
			Accept: 'application/json',
			'Accept-Language': 'da',
		};
		return config;
	}

	private buildGetParams(params) {
		return { params, timeout, withCredentials: this.withCredentials };
	}

	private buildPostParams(params) {
		return { ...params, timeout, withCredentials: this.withCredentials };
	}
}
