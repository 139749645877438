import { IContent, ContentReference } from './content';
export interface AcceptPageFieldBlock {
	fieldId: string;
	label: string;
	prefixLabel: string;
	postfixLabel: string;
	type: string;
	placeholder: string;
}

export interface AcceptPagePolicies {
	content: {
		type: string;
		policyFields: Array<AcceptPageFieldBlock>;
	};
}
export interface AcceptPage extends IContent {
	headline: string;
	contentType: string;
	canonicalUrl: {
		url: string;
		path: string;
	};
	offerIdLabel: string;
	customerInfoLabel: string;

	adjustPoliciesBlock: {
		sharedFields: Array<AcceptPageFieldBlock>;
	};
	policyBlock: {
		version: number;
		items: Array<AcceptPageFieldBlock>;
	};
	conditionsBlock: {
		stepHeadline: string;
		mainContent: string;
		checkboxLabel: string;
		secondaryContent: string;
	};
	consentsBlock: {
		stepHeadline: string;
		mainContent: string;
		acceptAllCheckboxLabel: string;
		phoneCheckboxLabel: string;
		textMessageCheckboxLabel: string;
		emailAndSelfServiceCheckboxLabel: string;
		letterCheckboxLabel: string;
		secondaryContent: string;
	};
	paymentMethodBlock: {
		stepHeadline: string;
		totalPriceLabel: string;
		feeLabel: string;
		selectPaymentMethodLabel: string;
	};
	previousInsuranceBlock: {
		stepHeadline: string;
		mainContent: string;
		checkboxLabel: string;
		secondaryContent: string;
	};
}

export interface ReceiptPage extends IContent {
	headline: string;
	contentType: string;
	canonicalUrl: {
		url: string;
		path: string;
	};
	contentArea: {
		items: Array<any>;
	};
	mainContent: string;
	secondaryContent: string;
}
export interface UsabillaInPageBlock extends IContent {
	usabillaId: string;
	usabillaCategory: string;
	usabillaSubCategory: string;
	usabillaItem: string;
	siteId: string;
	usabillaTitle: string;
	renderHtml: boolean;
	hide: boolean;
	enableAnimations: boolean;
}

/**   FOR CALCULATORS */

export enum TileVariantEnum {
	Navigation = 'Nav',
	Call = 'Call',
	App = 'App',
	Select = 'Select',
	Button = 'Button',
}
export interface TileBlock extends IContent {
	tileVariant: TileVariantEnum;
	title: string;
	icon: {
		url: string;
		type: string;
		properties: { contentType: string; height: number };
	};
	link: string;
	relativeURL: string;
	addQuery: boolean;
	phoneNumber: string;
	openingHours: string;
	modalContent: string;
	appMessage: string;
	selectGroupBtn: string;
	selectGroup: string;
	selectId: string;
	disabledWhen: string;
	selectMultiple: boolean;
	selectMultipleMax: number;
	selectActive: boolean;
	selectDisabled: boolean;
	externalLinkTarget: string;
	splashTxt: string;
	containerClass: string;
	buttonClass: string;
	newTab: boolean;
	renderHtml: boolean;
	hide: boolean;
	disabled: boolean;
	externalAction: Function;
	ignoreTouch: boolean;
}
export interface HtmlBlock extends IContent {
	markup: string;
	hide: boolean;
}

export interface HeaderCardBlock extends IContent {
	text: string;
	css: string;
	script: string;
}
export interface CardBlock extends IContent {
	image: string;
	title: string;
	headlineUnderline: boolean;
	text: string;
	linkText: string;
	routePath: string;
	buttonStyle: string;
	backgroundColor: string;
	noBorder: boolean;
	noBottomMargin: boolean;
	textCenter: boolean;
	cardHeight: string;
	inactivate: boolean;
	renderHtml: boolean;
	hide: boolean;
	enableAnimations: boolean;
}

export interface BasketPage extends IContent {
	frontendConfiguration: any;
	isLoginPage: boolean;
	backgroundImage: string;
	preTitle: string;
	mainTitle: string;
	mainArea: Array<IContent>;
	menu: ContentReference;
	disableAuthenticationCheck: boolean;
	templateRoot: ContentReference;
	hideMenus: boolean;
	siteSettings: ContentReference;
	globalContactSettings: ContentReference;
	showTitleInApp: boolean;
	metaTitle: string;
	metaDescription: string;
	metaKeywords: string;
	metaRobotsNoIndex: boolean;
	metaRobotsNoFollow: boolean;
	noSearch: boolean;
	noSearchForChildren: boolean;
	preScripts: Array<IContent>;
	bodyScripts: Array<IContent>;
	postScripts: Array<IContent>;
}

export interface CalculatorPage extends IContent {
	isLoginPage: boolean;
	backgroundImage: string;
	preTitle: string;
	mainTitle: string;
	mainArea: Array<IContent>;
	menu: ContentReference;
	disableAuthenticationCheck: boolean;
	templateRoot: ContentReference;
	hideMenus: boolean;
	siteSettings: ContentReference;
	globalContactSettings: ContentReference;
	showTitleInApp: boolean;
	metaTitle: string;
	metaDescription: string;
	metaKeywords: string;
	metaRobotsNoIndex: boolean;
	metaRobotsNoFollow: boolean;
	noSearch: boolean;
	noSearchForChildren: boolean;
	preScripts: Array<IContent>;
	bodyScripts: Array<IContent>;
	postScripts: Array<IContent>;
}

export type ContentTypes =
	| 'TileBlock'
	| 'HtmlBlock'
	| 'HeaderCardBlock'
	| 'CardBlock'
	| 'BasketPage'
	| 'CalculatorPage'
	| 'ReceiptPage'
	| 'AcceptPage'
	| 'AcceptPageFieldBlock'
	| 'AcceptPagePolicies'
	| 'UsabillaInPageBlock';
