import { mapState } from 'vuex';
import { CardBlock } from '@/cms/definitions/content-types';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'CampaignStepComponent',
	components: {},
	computed: mapState<any>({
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object, // Card from cms
	},
})
export default class CampaignStepComponent extends Vue {
	card: CardBlock; // Card from cms

	public cms!: any; // setting from store

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13) {
			this.nextStep();
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
