import { mapState } from 'vuex';
import FieldValidator from '@/views/calculators/services/fieldValidatorService';
import { AccidentSettings } from '../AccidentSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import store from '@/store/store';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import { Validator } from '../../services/ValidationService';
import { modalWithExit, togglePopup } from '../../services/AlertService';

@Options({
	name: 'PersonInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: AccidentSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;
	public defaultZip: string = null;
	public readonlyZip: string = null;
	public readonlyAge: string = null;
	public otherPerson = false;

	public created() {
		const zipParam = (this.$route.query.zipCode as string) || (this.$route.query.zipcode as string);
		if (zipParam) {
			this.defaultZip = zipParam;
		}
	}

	public mounted() {
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if ((!this.model.personInfo.zipCode || customerCalculation.hasCustomerData) && this.cms.focusFirstField) {
			// wait for transition to begin showing zipcode field
			setTimeout(() => {
				const input = document.querySelector('.vue3-autocomplete-container input') as any;
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout + 500);
		}

		window.addEventListener('keyup', this.moveOn);

		if (this.model.personInfo.otherName) {
			this.otherPerson = true;
			this.model.personInfo.isMainPerson = false;
			return;
		}

		if (this.model.multipleProducts) {
			const commonModel = store.getters.getCalculatorCommonModel;
			if (commonModel.personInfo.zipCode && commonModel.personInfo.zipName) {
				this.readonlyZip = this.model.personInfo.zipCode + ' ' + this.model.personInfo.zipName;
			}
			if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
				this.readonlyAge = this.model.personInfo.customerAge;
			}

			if (!this.model.personInfo.isMainPerson) {
				const basket = store.getters.getSelectableGroup('basket');
				const activeIndex = store.getters.getActiveCalculatorInx;

				let mainPerson = true;
				basket.forEach((product) => {
					if (
						mainPerson &&
						product.startsWith(this.model.productName) &&
						product !== this.model.productName + activeIndex
					) {
						const calc = store.getters.getCalculator(product);
						if (calc?.model?.personInfo?.isMainPerson) {
							mainPerson = false;
						}
					}
				});

				if (!mainPerson) {
					this.otherPerson = true;
					this.model.personInfo.isMainPerson = false;
				} else {
					this.otherPerson = false;
					this.model.personInfo.isMainPerson = true;
					this.model.personInfo.otherName = undefined;
					this.model.personInfo.otherCustomerAge = undefined;
					this.model.personInfo.otherCpr = undefined;
				}
			}
		} else {
			this.otherPerson = false;
			this.model.personInfo.isMainPerson = true;
			this.model.personInfo.otherName = undefined;
			this.model.personInfo.otherCustomerAge = undefined;
			this.model.personInfo.otherCpr = undefined;

			if (
				!this.readonlyAge &&
				customerCalculation.hasCustomerData &&
				this.fieldValidator.isValidAge(customerCalculation?.customer?.personInfo?.customerAge)
			) {
				this.readonlyAge = customerCalculation.customer.personInfo.customerAge;
			}
		}
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public zipSearch(data) {
		if (data.matchText) {
			this.model.personInfo.zipCode = data.zipCode;
			this.model.personInfo.zipName = data.zipName;
			this.model.pristineStep.set(this.card.name, true);
			nextTick(() => {
				// wait for transition to begin showing customerAge field
				setTimeout(() => {
					const input = document.getElementById('customerAge');
					if (input) {
						input.focus();
					}
				}, 300);
			});
			return;
		}
		this.model.personInfo.zipCode = undefined;
		this.model.personInfo.zipName = undefined;
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public workSelected(result) {
		if (result.matchText) {
			this.model.personInfo.work = result.matchText;
			const inx = this.cms.work.indexOf(result.matchText);
			this.model.personInfo.workId = this.cms.workIds[inx];
			return;
		}
		this.model.personInfo.work = undefined;
		this.model.personInfo.workId = undefined;
	}

	public get workLabel(): string {
		if (this.otherPerson && this.model.personInfo.otherName) {
			const name = this.model.personInfo.otherName.trim().split(' ');
			if (name[0].length > 1) {
				return `Hvad arbejder ${name[0]} med?`;
			}
		}
		return 'Hvad arbejder person med?';
	}
	public workSearchError() {
		const modal = modalWithExit(this.cms);
		modal.title = 'Problem med at finde dit job';
		modal.content =
			'Der er desværre i øjeblikket problemer med jobsøgning. Prøv igen om et øjeblik eller kontakt os.';
		modal.id = 'workSearchError';
		togglePopup(this.cms, modal);
	}
}
