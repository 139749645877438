import { Vue, Options } from 'vue-class-component';
import { PropType } from 'vue';
import { mapState } from 'vuex';
import { IContent } from '@/cms/definitions/content';
import ContentComponentSelector from '@/cms/components/infrastructure/contentComponentSelector/ContentComponentSelector.vue';
import getComponentTypeForContent from '@/cms/api/contentComponentSelector';

const ignoreBlocksInUi = ['UsabillaInPageBlock', 'HeaderCardBlock', 'HtmlBlock'];
@Options({
	name: 'ContentAreaComponent',
	components: {
		ContentComponentSelector,
	},
	computed: mapState<any>({
		isEditable: (state) => state.epiContext.isEditable,
	}),
	props: {
		model: Array as PropType<IContent[]>,
		rootClass: { type: String, default: 'ContentArea' },
		itemClass: { type: String, default: 'ContentAreaItem' },
	},
})
export default class ContentAreaComponent extends Vue {
	isEditable = false; // KLK ?
	model: IContent | any;
	rootClass: { type: string; default: 'ContentArea' };
	itemClass: { type: string; default: 'ContentAreaItem' };

	created(): void {
		this.model.forEach((block) => {
			block.hide = this.hideBlock(block);
		});
	}
	getComponentTypeForBlock(block: IContent) {
		return getComponentTypeForContent(block);
	}

	public hideBlock(block: IContent) {
		if (ignoreBlocksInUi.includes(block.contentType)) {
			return true;
		}
		return false;
	}

	getBlockClasses(block: IContent) {
		const clzz = (block as any)?.containerClass ? (block as any).containerClass : '';
		return `${this.itemClass} ${clzz}`;
	}
}
