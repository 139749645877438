import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input" }
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "flex input__field input__field--readonly"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!
  const _component_datepicker = _resolveComponent("datepicker")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        for: _ctx.innerId,
        class: "input__label",
        innerHTML: _ctx.label
      }, null, 8, _hoisted_2),
      (_ctx.popoverContent)
        ? (_openBlock(), _createBlock(_component_PopoverComponent, {
            key: 0,
            type: "icon",
            class: "page-component__popover text-left",
            buttonClass: "popover__icon popover__icon__questionmark",
            innerData: _ctx.popoverContent
          }, null, 8, ["innerData"]))
        : _createCommentVNode("", true),
      (!_ctx.readonlyValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
					{ 'input__field--valid': _ctx.isValid },
					{ 'input__field--error': _ctx.showError },
					{ 'input__field__calendar-icon': _ctx.showCalendarIcon },
				])
          }, [
            _createVNode(_component_datepicker, {
              class: "input__field",
              ref: "datePicker",
              modelValue: _ctx.pickedDate,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pickedDate) = $event)),
                _ctx.change
              ],
              id: _ctx.innerId,
              placeholder: _ctx.placeholder,
              inputFormat: _ctx.format,
              locale: _ctx.locale,
              typeable: true,
              lowerLimit: _ctx.lowerLimit ? _ctx.lowerLimit : undefined,
              upperLimit: _ctx.upperLimit ? _ctx.upperLimit : undefined,
              onBlur: _ctx.insureCorrectFormat,
              onFocus: _ctx.reset,
              onKeyup: _ctx.insureCorrectFormat
            }, null, 8, ["modelValue", "id", "placeholder", "inputFormat", "locale", "lowerLimit", "upperLimit", "onUpdate:modelValue", "onBlur", "onFocus", "onKeyup"])
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", { innerHTML: _ctx.readonlyValue }, null, 8, _hoisted_4)
          ])),
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: "input__field--error_message",
            innerHTML: _ctx.errorMsg
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}