import BuyHome from './BuyHomeComponent';
import AxiosService from '@/views/calculators/services/axiosService';
import store from '@/store/store';
import { defaultConfig } from '@/cms/api/ApiConfig';
import {
	EpiParamsHomeFamily,
	callAllCalculations,
	getRisksForPackage,
	mapCoveragesFromProductConfig,
	onePackageCalcEpi,
	prepareCalculations,
	setUpExcesses,
	setupCalculator,
	setupPackages,
	updateCalcKeys,
} from '../services/CalculationService';
import { HomeSettings } from './HomeSettings';
import { HomeModel } from './HomeModel';
import { PlusCustomerType } from '../services/ProductService';
import { Calculator } from '../BuyInsuranceComponent';

export default class HomeCalculator implements Calculator {
	public model: HomeModel; // the data structure for state and properties
	public cms: HomeSettings; // settings from EPI
	public calcConfig: any;
	public successCalc = true;
	public axiosService: AxiosService;
	private readonly calculatorUrl: string;
	public calculatorInfo: Array<any>;

	constructor(buyHome: BuyHome) {
		this.model = buyHome.model;
		this.cms = buyHome.cms;
		this.calculatorInfo = JSON.parse(buyHome.calculatorInfoBlock.markup);
		this.calculatorUrl = defaultConfig.baseUrl + store.state.openServiceCalculatorUrl + '/insurance/home/quotes';
		this.axiosService = new AxiosService(this.calculatorUrl);
		this.setUpConfig();
	}

	public setUpConfig() {
		setupCalculator(this, this.calculatorInfo);

		const calculatorInfo = this.calculatorInfo.find(
			(cfg) => cfg.calculatorConfigId === this.cms.calculatorConfigId
		);

		this.model.calculation.abCalc.insuredValueOptions = calculatorInfo.insuredValueOptions;
		this.model.calculation.abCalc.familyMemberCountOptions = calculatorInfo.familyMemberCountOptions;

		setupPackages(this.model, this.cms, this.calcConfig);

		mapCoveragesFromProductConfig(this.model, this.cms);
		updateCalcKeys(this.model);
	}

	public setupExcessList() {
		setUpExcesses(this.model, this.calcConfig.excessList);
	}

	public async getCalculations(excessId?: number): Promise<boolean> {
		const selectedExcessId =
			excessId || this.model.choosePackage.ownRiskId || this.model.calculation.abCalc.excessIdDefault;
		prepareCalculations(this.model, this.cms, true, selectedExcessId);
		return callAllCalculations(this, excessId, true, 'Home calculator');
	}

	public async onePackageCalcEpi(monthly: boolean, excessId: number, packageId: number) {
		return await onePackageCalcEpi(this, monthly, excessId, packageId, true, 'Home calculator');
	}

	public createParamsEpi(excessId: number, packageId: number, monthlyPrices: number) {
		const res = EpiParamsHomeFamily(this.model);
		// create params
		const params = {
			district_name: 'xxx', // ?
			customer_age: res.customerAge,
			insured_value: res.insuredValue,
			street_name: this.model.personInfo.streetName,
			street_building: res.streetNumber,
			street_building_letter: res.streetBuildingLetter !== '' ? res.streetBuildingLetter : undefined,
			floor: this.model.personInfo.floorNr,

			suit: res.suite, // hvis tal
			floor_side_code: res.floorSideCode, // hvis ikke tal

			post_code: `${this.model.personInfo.zipCode}`,
			has_thatched_roof: this.model.personInfo.thatchedRoof === 'ja' ? 'y' : 'n',
			has_burglar_alarm: 'n',
			family_member_count: this.model.personInfo.familyMemberCountId,
			plus_customer: PlusCustomerType.PK0.toUpperCase(),
			excess_id: `${excessId}`,
			payments_per_year: monthlyPrices === 1 ? 12 : 1,
			selected: getRisksForPackage(packageId, this.model),
			// kvhxValue: this.model.personInfo.kvhxValue, // ?
			// optional

			// selectedOptionalProducts: '46,9,8',
			// zipName: `${this.model.personInfo.zipName}`,
			// extendedBikeSum: this.cms.extendedBikeSum // is present in familyinsurance, currently not in homeInsurance
			// extendedPackage: `${packageId}`,
		};
		return params;
	}
}
